var instagram = function(){
	'use strict';
	var now, since, until;
	var client_id = '942f0188225247bbb45915ac771c1e44';
	var countRequests = 0;
	var month = null;

    var arrayMonths = {
        January: 01,
        February: 02,
        March: 03,
        April: 04,
        May: 05,
        June: 06,
        July: 07,
        August: 08,
        September: 09,
        October: 10,
        November: 11,
        December: 12
    }

	var instagram = {
		request: function(path,data,callback){
			countRequests++;
			$.post(path,data,function(response){
				countRequests--;
				callback(response);
			})
		},

		createReport: function(){
			if( today.getDate()==date.getDate() &&
                today.getMonth()==date.getMonth() &&
                today.getYear()==date.getYear() )
            {
                until = Math.floor( today.getTime()/1000);
            }
            else until = Math.floor(date.getTime()/1000);

            var currentDate = new Date( until*1000 );
            currentDate = Math.floor(currentDate.setHours(0,0,0,0)/1000 )
            //ajuste para buscar posts que foram feitos no as 00:00 do dia
            until--;

            date.setHours(0,0,0,0)/1000;
            since = Math.floor(date.setDate('1')/1000);

            //set since and until for filters
            $('.tabs .reports') .attr('data-since',since)
                                .attr('data-until',until)

            // instagram.initReport();
		},

		initReport: function(){
			for( var pageId in pages )
            {
            	var path = rootURL+'instagram/update';
				var data = {pageId: pageId, since: since, until: until };
				instagram.request(path, data,instagram.checkFinished);
			}
		},

		checkFinished: function(){
			if(countRequests == 0)
            {
                dashboard.init();
				dashboard.refresh();
            }
		},

		openPages: function(){
			var since = $('.tabs .reports').attr('data-since')
            var until = $('.tabs .reports').attr('data-until')

            FB.getLoginStatus( function(response)
            {
                if (response.status === 'connected')
                {
                    $.post(rootURL+'instagram/tablePages',{since:since,until:until},function(data){
                        if(data.logged)
                        {
                            $('.relatorio').replaceWith(data.html);
                            dashboard.close();
                            var $table = $('table.table-striped');
                            $table.floatThead({
                                scrollContainer: function($table){
                                    return $table.closest('.wrapper');
                                }
                            });

                            tables.tableRelatorio(true);
                            instagram.updateDateField();
                            instagram.getPageInsights();
                        }
                        else
                        {
                            window.location = rootURL;
                        }
                    },'json')
                }
                else
                {
                    window.location = rootURL;
                }
            });
        },
        
        getPageInsights: function(){
            for (var id in pages)
            {
                var since = $('.tabs .reports').attr('data-since')
                var until = $('.tabs .reports').attr('data-until')

                var path = id+'/insights?metric=impressions,reach&period=days_28'+
                            '&until='+until+
                            '&access_token='+fbToken
                facebook.config.countRequests++;
                facebook.methods.request( path , instagram.storePageInsights );
            }
        },

        storePageInsights: function(facebookData){
            var info = [];
            if( 'data' in facebookData)
            {
                //loop to get all results
                for (var i = facebookData.data.length - 1; i >= 0; i--) {
                    if(facebookData.data[i].period == 'days_28')
                        info[facebookData.data[i].name] = facebookData.data[i].values[1].value;
                }
            }
            $('[data-pagereach]').html(info['reach']);
            $('[data-pageimpressions]').html(info['impressions']);
        },

		openPosts: function(){
			var since = $('.tabs .reports').attr('data-since')
            var until = $('.tabs .reports').attr('data-until')
            var keyword = $('.tabs .reports').attr('data-keyword')

            FB.getLoginStatus( function(response)
            {
                if (response.status === 'connected')
                {
                    $.post(rootURL+'instagram/tablePosts',{since:since,until:until,keyword:keyword},function(data){
                        if(data.logged)
                        {
                            $('.relatorio').replaceWith(data.html);
                            dashboard.close();
                            var $table = $('table.table-striped');
                            $table.floatThead({
                                scrollContainer: function($table){
                                    return $table.closest('.wrapper');
                                }
                            });

                            tables.tableRelatorio(false,10);
                            instagram.updateDateField();
                        }
                        else
                        {
                            window.location = rootURL;
                        }
                    },'json')
                }
                else
                {
                    window.location = rootURL;
                }
            });
		},

		openRanking: function(){

            FB.getLoginStatus( function(response)
            {
                if (response.status === 'connected')
                {
                    $.get(rootURL+'instagram/tableRanking',{month:month},function(data){

                        if(data.logged)
                        {
                            $('.relatorio').replaceWith(data.html);
                            dashboard.close();

                            var engagementCollumn = ($('.table-relatorio th').length) - 1;

                            var t = $('.table-relatorio').DataTable({
                                searching: false,
                                paging: false,
                                info: false,
                                "order": [[ engagementCollumn, "desc" ]],
                                ordering:  true
                            });

                            t.on( 'order.dt search.dt', function () {
                                t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                                    cell.innerHTML = i+1;
                                } );
                            } ).draw();

                            $('.dataTables_length').appendTo('.search-date');
                            $('.dataTables_info, .dataTables_paginate').appendTo('.table-pagination');

                            $('.months').datepicker({
                                format: 'MM',
                                viewMode: 'year',
                                minViewMode: 'year',
                                language: 'en-US',
                                autoclose: true,
                                minViewMode: 1,
                            }).on('changeDate', function(e) {
                                var currentVal = $(this).val();
                                $.each(arrayMonths, function(index, val) {
                                    if (index === currentVal) {
                                        $('.months').attr('data-date', val);
                                    };
                                });
                            });

                            if(data.month == '')
                                var mm = today;
                            else
                                var mm = data.month;

                            if(mm<10)
                                mm='0'+mm;

                            // set date to calendar
                            $( ".months" ).datepicker( "setDate", mm );

                            $('.table-relatorio').floatThead();

                            t.order( [ engagementCollumn, 'desc' ] ).draw();
                        }
                        else
                        {
                             window.location = rootURL;
                        }
                    },'json')
                }
                else
                {
                    window.location = rootURL;
                }
            });
        },

        searchPages: function(){
            dashboard.open('engagement');
            instagram.updateDateFilter();
            instagram.openPages();
        },

        searchPosts: function(){
            dashboard.open('post-performance');
            instagram.updateDateFilter();
            instagram.openPosts();
        },

        updateDateFilter: function(){
            var sinceInput = $('.since').val();
            var untilInput = $('.until').val();
            var keywordInput = $('.keyword').val();

            var sinceValue = sinceInput.split('/');
            var untilValue = untilInput.split('/');

            date = new Date( sinceValue[2],parseInt(sinceValue[1],'10')-1,sinceValue[0],0,0,0,0 );
            since = Math.floor(date.getTime()/1000);
            
            date = new Date( untilValue[2],parseInt(untilValue[1],'10')-1,untilValue[0],23,59,59,0 );

            if( today.getDate()==date.getDate() &&
                today.getMonth()==date.getMonth() &&
                today.getYear()==date.getYear() )
            {
                until = Math.floor( today.getTime()/1000);
            }
            else until = Math.floor(date.getTime()/1000);
   
            var currentDate = new Date( until*1000 );
            currentDate = Math.floor(currentDate.setHours(0,0,0,0)/1000 )
            //ajuste para buscar posts que foram feitos no as 00:00 do dia
            // until--;

            //set since and until for filters
            $('.tabs .reports') .attr('data-since',since)
                                .attr('data-until',until)
                                .attr('data-keyword',keywordInput)
        },

        updateDateField: function(){
            var since = $('.tabs .reports').attr('data-since')
            var until = $('.tabs .reports').attr('data-until')
            var keyword = $('.tabs .reports').attr('data-keyword')

            var dateSince = new Date(since*1000)
            var dateUntil = new Date(until*1000)
            
            $('.search .until').datepicker("setDate", pad(dateUntil.getDate(),2) + '/' + pad((dateUntil.getMonth()+1),2) + '/' + dateUntil.getFullYear())
            $( ".search .since" ).datepicker( "setDate", pad(dateSince.getDate(),2) + '/' + pad((dateSince.getMonth()+1),2) + '/' + dateSince.getFullYear() );
            $('.search .keyword').val(keyword);
        },

        updateRanking: function(){
            // for(var page in pagesForRanking)
            // {
            //     if(pagesForRanking[page] != 0)
            //     {
            //         $.post(rootURL+'instagram/update-posts',{instagram:pagesForRanking[page],since:since,until:until},function(data){
                        
            //         })
            //     }
            // }
        },

		events: function(){
			//pages
			$('.list-tabs.reports [data-tab="engagement"]').on('click',instagram.openPages);
            $('html').on('click','[data-tab="engagement"] .bt-search',instagram.searchPages);
			$('html').on('click','.bt-csv',function(event) {
			    event.preventDefault();
			    $('#formReport').submit();
			});


			$('.list-tabs.reports [data-tab="post-performance"]').on('click',instagram.openPosts);
            $('html').on('click','[data-tab="post-performance"] .bt-search',instagram.searchPosts);
            $('html').on('keyup','[data-tab="post-performance"] .keyword',function(e){
                if(e.which == 13) {
                    instagram.searchPosts(e);
                }
            });


			$('.list-tabs.reports [data-tab="ranking"]').on('click',instagram.openRanking);

            $('html').on('click','[data-tab="ranking"] .bt-search',function(e){
                e.preventDefault();
                dashboard.open('ranking');
                month = $('input.months').attr('data-date');
                instagram.openRanking();
            });

		},

        openAuth: function(e){
            e.preventDefault();

            var popupWidth = 700,
                popupHeight = 500,
                popupLeft = (window.screen.width - popupWidth) / 2,
                popupTop = (window.screen.height - popupHeight) / 2;

            var instagramClientId = '942f0188225247bbb45915ac771c1e44';
            var instagramRedirectUri = rootURL+'instagram/token/'; 
            window.open('https://instagram.com/oauth/authorize/?client_id='+instagramClientId+'&response_type=code&scope=public_content&redirect_uri='+instagramRedirectUri, '', 'width='+popupWidth+',height='+popupHeight+',left='+popupLeft+',top='+popupTop+'');
            // popup.open(, '_self');
        },

		init: function(){
            graphapi_instagram.init();
            instagram.events();
            instagram.createReport();
            // dashboard.init();
            // dashboard.refresh();
            $('.relatorio').removeClass('show-allow');
		}
	}

	return instagram;
}();