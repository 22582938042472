var postperformance = function(){
	'use strict';

	var now, since, until, report, posts, firstTime;
    var firstTime = true;
    var urlCurrent = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1),
        urlReplace = urlCurrent.substring(urlCurrent.lastIndexOf('/') + 1);

    var postsDeleted;

	var postperformance = {

		formatDate: function(){
			$('.until').val( date.getDate()+'/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear() );
			date.setHours(0,0,0,0);
			$('.since').val('01/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear());
		},

		initReport: function(){

            if( today.getDate()==date.getDate() &&
                today.getMonth()==date.getMonth() &&
                today.getYear()==date.getYear() )
            {
                until = Math.floor( today.getTime()/1000);
            }
            else until = Math.floor(date.getTime()/1000);

            var currentDate = new Date( until*1000 );
            currentDate = Math.floor(currentDate.setHours(0,0,0,0)/1000 )
            //ajuste para buscar posts que foram feitos no as 00:00 do dia
            until--;


			since = Math.floor(date.setDate('1')/1000);
			facebook.config.until =  until ;
    		facebook.config.since = since;
    		facebook.config.now = until;
    		facebook.config.currentDate = currentDate;
    		facebook.config.callback = postperformance.sortReport;
			facebook.methods.initReport();

		    $('.table-responsive thead th').removeAttr('style');
		},

        removeAcento: function(strToReplace) {
            var str_acento= "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ";
            var str_sem_acento = "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC";
            var nova="";

            for (var i = 0; i < strToReplace.length; i++)
            {
                if (str_acento.indexOf(strToReplace.charAt(i)) != -1)
                {
                    nova+=str_sem_acento.substr(str_acento.search(strToReplace.substr(i,1)),1);
                }
                else
                {
                    nova+=strToReplace.substr(i,1);
                }
            }

            return nova;
        },

		sortReport: function(field,order){
            var posts = [];
            var i = 0;
            var admin = parseInt($('.tabs .reports').attr('data-admin'));

            _.each(report.pages, function(element, index, list){

                //filter for admin pages
                if(admin === 1 || admin === 0)
                    if(admin != element.admin)
                        return true


                if(element.totalPosts > 0)
                {
                    var shopping = element.name,
                        totalEngagement = element.totalEngagement;

                    _.each(element.posts,function(element,index,list){

                        var message = (_.isString(element.message))?element.message:'';

                        if(facebook.config.keyword)
                        {
                            var searchMessage = postperformance.removeAcento(message.toLowerCase());
                            var searchKeyword = postperformance.removeAcento(facebook.config.keyword.toLowerCase());
                            if(searchMessage.indexOf(searchKeyword) < 0)
                                return true
                        }

                        posts[i] = {};
                        posts[i].shopping = shopping

                        posts[i].likes = parseInt(element.likes)
                        posts[i].shares = parseInt(element.shares)
                        posts[i].comments = parseInt(element.comments)
                        posts[i].views = parseInt(element.view)
                        posts[i].anger = parseInt(element.anger)
                        posts[i].haha = parseInt(element.haha)
                        posts[i].like = parseInt(element.like)
                        posts[i].love = parseInt(element.love)
                        posts[i].sad = parseInt(element.sad)
                        posts[i].wow = parseInt(element.wow)
                        posts[i].engagement = parseFloat(element.engagement);
                        posts[i].percentage = parseFloat(element.engagement*100/totalEngagement);
                        if( isNaN(posts[i].percentage) ) posts[i].percentage=0;

                        posts[i].reach = parseInt(element.reach)
                        posts[i].reachPaid = parseInt(element.reachPaid)
						posts[i].reachUnpaid = parseInt(element.reachUnpaid)

                        posts[i].impressions = parseInt(element.impressions)

                        posts[i].story = element.story
                        posts[i].link = element.link
                        posts[i].message = message
                        posts[i].picture = element.picture
                        posts[i].type = element.type
                        posts[i].created_at = element.created_at

                        posts[i].facebook_id = element.post_id
                        posts[i].page_facebook_id = element.page_id
                        posts[i].class = (element.admin)?'admin':'';
                        i++;

                    });
                }
            })

            postperformance.posts = posts;

            if(firstTime)
            {
                dashboard.init();
                firstTime = false;
                if(urlReplace != 'post-performance')
                    postperformance.savePosts();
            }
            else
            {
                postperformance.populatePosts();
                postperformance.savePosts();
            }

        },

        populatePosts: function(field,order){
            var posts = postperformance.posts;
            if(posts.length>0)
            {
                $('.bt-salvar, .bt-imprimir').removeClass('disable-button');
            }
            else
            {
                $('.bt-salvar, .bt-imprimir').addClass('disable-button');
            }

            if(field)
            {
                if(order == 'desc')
                    posts = _.sortBy(posts,field).reverse();
                else
                    posts = _.sortBy(posts,field);
            }
            else
                posts = _.sortBy(posts,'engagement').reverse();

            var template = $( '.posts-management' ).html()
            var templateCompiled = _.template( template)

            $( '.table-post tbody' ).html('')

            _.each(posts,function(el,i,list){
                $( '.table-post tbody' ).append( templateCompiled({ post: el }) )
            })

            var $table = $('table.table-striped');
            $table.floatThead({
                scrollContainer: function($table){
                    return $table.closest('.wrapper');
                }
            });

            $('.table-responsive').removeClass('loading');


            $('.bt-postDeleted .count').html('0');
            $('.bt-postDeleted').addClass('disable');
            $('#report').val(JSON.stringify(report));

            postperformance.printHeader();
            tables.tableRelatorio(false,19);
            dashboard.close();

            setTimeout(function(){
                $('.search .admin').customSelect();
            },500)
        },

        savePosts: function(){
            var posts = postperformance.posts;
            var since = $('.since').val()
            var until = $('.until').val()

            report.postsDeleted = [];

            //tranform in 2 partes, to prevent erro: 413 Request Entity Too Large
            var numberOfParts = 2
            var result = _.groupBy(posts, function(item, i) {
                return Math.floor(i % numberOfParts);
            });
            var parts = _.values(result);

            // $.each(parts,function(i,el){
        	//    var stringPosts = JSON.stringify(el);
			//
            // 	$.post(
            // 	    rootURL+'posts/save',
            // 	    {
            // 	        posts: stringPosts,
            // 	        since: since,
            // 	        until: until
            // 	    },
            // 	    function(data){
            //             // console.log(data)
            // 	        if (typeof data.posts != 'undefined' && data.posts != null)
            // 	        {
            // 	            if (data.posts.length > 0 && !facebook.config.keyword){
            // 	                $('.bt-postDeleted .count').html(data.posts.length);
            // 	                postsDeleted = data.posts;
            // 	                $('.bt-postDeleted').removeClass('disable');
            //                 }else{
            // 	                $('.bt-postDeleted .count').html('0');
            // 	                $('.bt-postDeleted').addClass('disable');
            // 	            }
            //             }
            //     },'json')

        	var stringPosts = JSON.stringify(posts);

        	$.post(
        	    rootURL+'posts/save',
        	    {
        	        posts: stringPosts,
        	        since: since,
        	        until: until
        	    },
        	    function(data){
                    // console.log(data)
        	        if (typeof data.posts != 'undefined' && data.posts != null)
        	        {
        	            if (data.posts.length > 0 && !facebook.config.keyword){
        	                $('.bt-postDeleted .count').html(data.posts.length);
        	                postsDeleted = data.posts;
        	                $('.bt-postDeleted').removeClass('disable');
                        }else{
        	                $('.bt-postDeleted .count').html('0');
        	                $('.bt-postDeleted').addClass('disable');
        	            }
                    }
            },'json')


            // nao tá legal...
            dashboard.refresh();
        },

        printHeader: function(){
        	var template = $( '.template-dados-fanpage' ).html()
        	var templateCompiled = _.template( template)

        	$('.dados-destaque').html('');

        	_.each(report.pages, function(element, index, list){
        	    element.percent = (((element.totalEngagement/element.totalPosts)/element.fans)*10000).toFixed(2)
        	    $( '.dados-destaque' ).append( templateCompiled({ page: element }) )
        	});
        },

        search: function(e){

    	    e.preventDefault();
            dashboard.open('post-performance');
    	    //Se for post post-performance limpa o tbody
    	    $('.bt-csv, .bt-print').addClass('disable-button');
    	    $('.bt-postDeleted').addClass('disable');
    	    $('.table-responsive').removeClass('delete-table').addClass('loading').html(tableHtml);
    	    $('.posts-deleted').html('Posts deleted ...')
    	    $('.table-post tbody').html('');

    	    var sinceInput = $('.since').val();
            var untilInput = $('.until').val();
    	    var keywordInput = $('.keyword').val();
            var adminFilter = $('.search .admin').val();

    	    var sinceValue = sinceInput.split('/');
    	    var untilValue = untilInput.split('/');

    	    date = new Date( sinceValue[2],parseInt(sinceValue[1],'10')-1,sinceValue[0],0,0,0,0 );
    	    since = Math.floor(date.getTime()/1000);

    	    date = new Date( untilValue[2],parseInt(untilValue[1],'10')-1,untilValue[0],23,59,59,0 );

    	    if( today.getDate()==date.getDate() &&
    	        today.getMonth()==date.getMonth() &&
    	        today.getYear()==date.getYear() )
    	    {
    	        until = Math.floor( today.getTime()/1000);
    	    }
    	    else until = Math.floor(date.getTime()/1000);

    	    var currentDate = new Date( until*1000 );
    	    currentDate = Math.floor(currentDate.setHours(0,0,0,0)/1000 )
    	    //ajuste para buscar posts que foram feitos no as 00:00 do dia
    	    // until--;

            //set since and until for filters
            $('.tabs .reports') .attr('data-since',since)
                                .attr('data-until',until)
                                .attr('data-keyword',keywordInput)
                                .attr('data-admin',adminFilter)

            postperformance.renderTable()

    	    facebook.methods.createReport();
    		facebook.config.now = until;
    		facebook.config.currentDate = currentDate;
            //ajuste para buscar posts que foram feitos no as 00:00 do dia
            since--;
    		facebook.config.since = since;
            facebook.config.until =  until;
    	    facebook.config.keyword =  keywordInput;
    		facebook.config.callback = postperformance.sortReport;
			facebook.methods.initReport();
        },

        open: function(e){
            FB.getLoginStatus( function(response)
            {
                if (response.status === 'connected')
                {
                    setTimeout(function(){
                        postperformance.renderTable()
                        postperformance.sortReport();
                        dashboard.close();
                    },600)
                }
                else
                {
                    window.location = rootURL;
                }
            });
        },

        renderTable: function(){
            var since = $('.tabs .reports').attr('data-since')
            var until = $('.tabs .reports').attr('data-until')
            var keyword = $('.tabs .reports').attr('data-keyword')
            var admin = $('.tabs .reports').attr('data-admin')

            var dateSince = new Date(since*1000)
            var dateUntil = new Date(until*1000)

            var template = $('.template-section-post').html();
            $('.relatorio').replaceWith(template);

            $('.search .since').val(pad(dateSince.getDate(),2) + '/' + pad((dateSince.getMonth()+1),2) + '/' + dateSince.getFullYear())
            $('.search .until').val(pad(dateUntil.getDate(),2) + '/' + pad((dateUntil.getMonth()+1),2) + '/' + dateUntil.getFullYear())
            $('.search .keyword').val(keyword);
            $('#formReport .keyword').val(keyword);
            $('.search .admin') .val(admin);
        },

        postsDeleted: function(event){
            postperformance.renderTable();

            if($(this).hasClass('showall'))
            {
                $(this).removeClass('showall');
                postperformance.sortReport();
                return true;
            }


            var posts = [];
            var i = 0;

            _.each(postsDeleted,function(element,index,list){
                posts[i] = {};
                posts[i].shopping = element.shopping

                posts[i].likes = parseInt(element.likes)
                posts[i].shares = parseInt(element.shares)
                posts[i].comments = parseInt(element.comments)
                posts[i].views = parseInt(element.views)
                posts[i].engagement = parseFloat(element.engagement);
                posts[i].percentage = 0;

                posts[i].link = element.link
                posts[i].message = (_.isString(element.message))?element.message:''
                posts[i].picture = element.picture
                posts[i].created_at = element.created_at

                posts[i].facebook_id = element.facebook_id
                posts[i].page_facebook_id = element.page_facebook_id
                i++;
            });

            var template = $( '.posts-management' ).html()
            var templateCompiled = _.template( template)

            $( '.table-post tbody' ).html('')

            _.each(posts,function(el,i,list){
                $( '.table-post tbody' ).append( templateCompiled({ post: el }) )
            })

            var $table = $('table.table-striped');
            $table.floatThead({
                scrollContainer: function($table){
                    return $table.closest('.wrapper');
                }
            });

            $('.bt-postDeleted').removeClass('disable')
                                .addClass('showall')
                                .html("Show All");
        },

		hidePost: function(event){
			event.preventDefault();
			var post_id = $(this).attr('data-id');
			$.post(rootURL+'posts/hide', {post_id:post_id}, function(response) {
				console.log(response)
			});
		},

		events: function(){

			$('html').on('click','[data-tab="post-performance"] .bt-csv',function(event) {
			    event.preventDefault();

			    if( !$(this).hasClass('blocked') )
			    {
			        $('#formReport').submit();
			    }
			});

            $('html').on('click','[data-tab="post-performance"] .bt-search',postperformance.search);
            $('html').on('click','[data-tab="post-performance"] .bt-postDeleted',postperformance.postsDeleted);
            $('.list-tabs.reports [data-tab="post-performance"]').on('click',postperformance.open)
            $('html').on('keyup','[data-tab="post-performance"] .keyword',function(e){
                if(e.which == 13) {
                    postperformance.search(e);
                }
            });

			// ideia futuro
			// $('html').on('click','[data-tab="post-performance"] .hide-post',postperformance.hidePost);
		},

		init: function(){
			report  = facebook.report;

			postperformance.events();
			// postperformance.createReport();
			// postperformance.formatDate();
			// postperformance.initReport();
		}
	}

	return postperformance;
}();
