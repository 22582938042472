//facebook.js
var facebook = function(){
	'use strict';
    var now, since, until;
    var report = { pages : {}, formatedPages:[], postsPerPage : [], posts : [], postsDeleted : [], postsRelated : [], relatedDone: [], pagesNotAdmin: [] };
    var config = { currentDate: date, now : date, since : date, until : date, keyword: '', countRequests : 0, callback : null };
    var methods = {
        accessToken:null,
        pages: [],

		tab: function(){
            var tabCurrent = $('.inner').attr('data-tab');
            $('.tab[data-tab='+tabCurrent+']').addClass('active');
        },

		init: function(){
            facebook.methods.tab();
            facebook.methods.checkStatus();
        },

        checkStatus: function(){
            var that = this;
            $('.login').addClass('loading-facebook');

            window.fbAsyncInit = function() {

                // Facebook configuration
                FB.init({
                    appId      : '548437818553082',
                    status     : true,
                    xfbml      : true,
                    version    : 'v18.0',
                    cookie     : true
                });
			    // Verifies if the user has already accepted the app
			    FB.getLoginStatus( function(response)
			    {

                    // If user already logged
                    if (response.status === 'connected')
                    {
                        // Get a fresh access token and keep it
                        facebook.methods.accessToken = response.authResponse.accessToken;

                        // Login the user in the app
                        // The server recieve the access token and check if
                        // this access token is from a valid user
                        if(section == 'login')
                        {
                            graphapi_instagram.getPermissions();
							//vamos vericar as permissões do usuariotc
							var path = '/me/permissions';

				            config.countRequests++;
				            facebook.methods.request( path , facebook.methods.checkPermissions );
                            // facebook.methods.getUserPages();
                        }
                        else
                        {
                            if(socialnetwork == 'facebook')
                            {
                                // //facebook classes
                                engagement.init();
                                postperformance.init();
                                ranking.init();

                                facebook.methods.createReport();
                                facebook.methods.selfInit(postperformance.sortReport);
                            }
                            else if(socialnetwork == 'instagram')
                            {
                                instagram.init();
                            }
                        }
                    }else{
                        $('.bt-entrar').removeClass('blocked');
                        $('.login').removeClass('loading-facebook');
                        $('.login').addClass('logar-facebook');
                    }
			    });
			}
		},

		checkPermissions: function(response){
			if(response.data)
			{
				for (var i = response.data.length - 1; i >= 0; i--)
				{
					// console.log(response.data[i]);
				}
				$('.bt-entrar').removeClass('blocked');
				$('.login').removeClass('loading-facebook');
				$('.login').addClass('logar-facebook');
			}
		},

		login: function(event){
			var that = this;

            // console.log('aqui?');
			if(event)
            	event.preventDefault();

            $('.login').addClass('loading-facebook');
            // console.log('login');
            // if( !facebook.methods.accessToken )
            // {
                // console.log('accesstoken nao tem');

                var $btnEntrar = $(this);
                if( !$btnEntrar.hasClass('blocked') )
                {
                    $btnEntrar.addClass('blocked');

                    FB.login( function( response )
                    {
						// console.log(response)
                        $btnEntrar.removeClass('blocked');
                        // console.log(response);
                        // If the user accepted
                        if (response.status === 'connected')
                        {
                            // Get a fresh access token and keep it
                            if( response.authResponse )
                            {
                                facebook.methods.accessToken = response.authResponse.accessToken;
                                facebook.methods.getUserPages();
                            }
                            else{
                                alert('We weren\'t able to retrieve data from facebook. Please try again later.');
                                $('.login').removeClass('loading-facebook');
                            }



                        // } else if (response.status === 'not_authorized') {
                        } else{
                          // the user is logged in to Facebook,
                          // but has not authenticated your app
                          alert('You didn\'t authorized correctly the app. Remove the app from your facebook account and try again.' );
                          $('.login').removeClass('loading-facebook');
                        }
                    }, { scope: 'pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content, read_insights, instagram_basic, instagram_manage_insights'});
                }
            // }
            // else
            // {
            //     facebook.methods.getUserPages();
            // }
		},

        getUserPages: function(){
            // Login the user in the app
            // The server recieve the access token and check if
            // this access token is from a valid user
            // console.log('aqui')
            $.post(rootURL+'login',{ access_token: facebook.methods.accessToken },
                function( serverResponse ) {
                    // console.log(pages)
                    // If the user doesn`t have pages selected
                    // else if( serverResponse=='not_allowed' ){
                    // }
                    try{
                        var result = JSON.parse( serverResponse )

                        login.checkPermission(result);
                    }
                    catch(e){
                        $('.loading-facebook').removeClass('loading-facebook');
                        alert(serverResponse.replace(/(<([^>]+)>)/ig,""));
                    }
                }
            )
        },

        request: function(path , callback){
           FB.api( path, function(response)
           {
                config.countRequests--;
                // console.log(config.countRequests)
                callback( response );
           });
        },

        createReport: function(){

            for( var pageId in pages ){
               if( /[0-9]+/.test(pageId) )
                {
                    // Report data
                    facebook.report.pages[ pageId ] = { name: pages[ pageId ] };

                    // Initialize count variables
                    facebook.report.pages[ pageId ].id = pageId;
					facebook.report.pages[ pageId ].token = 0;
					facebook.report.pages[ pageId ].fans = 0;
                    facebook.report.pages[ pageId ].posts = {};
                    facebook.report.pages[ pageId ].totalPosts = 0;
					facebook.report.pages[ pageId ].totalLikes = 0;
					facebook.report.pages[ pageId ].totalLike = 0;
					facebook.report.pages[ pageId ].totalAnger = 0;
					facebook.report.pages[ pageId ].totalHaha = 0;
					facebook.report.pages[ pageId ].totalLove = 0;
					facebook.report.pages[ pageId ].totalSad = 0;
                    facebook.report.pages[ pageId ].totalWow = 0;
                    facebook.report.pages[ pageId ].totalShares = 0;
                    facebook.report.pages[ pageId ].totalComments = 0;
                    facebook.report.pages[ pageId ].totalViews = 0;
                    facebook.report.pages[ pageId ].totalEngagement = 0;
                    facebook.report.pages[ pageId ].engagement = 0;
                    facebook.report.pages[ pageId ].avgEngagement = 0;

                    facebook.report.pages[ pageId ].totalReachPaid = 0;
                    facebook.report.pages[ pageId ].totalReachUnpaid = 0;
					facebook.report.pages[ pageId ].totalReach = 0;
                    facebook.report.pages[ pageId ].reach = 0;
                    facebook.report.pages[ pageId ].reachPaid = 0;
					facebook.report.pages[ pageId ].reachUnpaid = 0;

					facebook.report.pages[ pageId ].impressions = 0;
					facebook.report.pages[ pageId ].totalImpressions = 0;
					facebook.report.pages[ pageId ].involvement = 0;
                    facebook.report.pages[ pageId ].totalInvolvement = 0;
					facebook.report.pages[ pageId ].interactions = 0;
                    facebook.report.pages[ pageId ].totalInteractions = 0;
                    
                    facebook.report.pages[ pageId ].remainingPosts = 0;
                }
           }
        },

        selfInit: function(callback){
            if( today.getDate()==date.getDate() &&
                today.getMonth()==date.getMonth() &&
                today.getYear()==date.getYear() )
            {
                until = Math.floor( today.getTime()/1000);
            }
            else until = Math.floor(date.getTime()/1000);

            var currentDate = new Date( until*1000 );
            
            //ajuste para buscar posts que foram feitos no as 00:00 do dia
            until--;

            date.setHours(0,0,0,0)/1000;
            // since = Math.floor(date.setDate('1')/1000);
            if(currentDate.getDate() > 1)
            {
                var initDay = currentDate.getDate()-1;
                since = Math.floor(date.setDate(initDay)/1000);
            }
            else
            {
                since = Math.floor(date.setDate('1')/1000);
            }

            //set since and until for filters
            $('.tabs .reports') .attr('data-since',since)
                                .attr('data-until',until)

            currentDate = Math.floor(currentDate.setHours(0,0,0,0)/1000 )

            facebook.config.until =  until;
            facebook.config.since = since;
            facebook.config.now = until;
            facebook.config.currentDate = currentDate;
            facebook.config.callback = callback;
            facebook.methods.initReport();
        },

        initReport: function(){
            //zera alguns dados
            facebook.report.postsPerPage = [];
            facebook.report.posts = [];
            facebook.report.postsDeleted = [];
            facebook.report.postsRelated = [];
            facebook.report.relatedDone = [];
            facebook.report.pagesNotAdmin = [];

            // sempre inicia buscando page fans
            var path = 'me/accounts?access_token='+fbToken;

			// console.log(facebook.report);
            config.countRequests++;
            facebook.methods.request( path , facebook.methods.getPagesToken );
        },

		getPagesToken: function(facebookData){

			for(var i in facebookData.data)
			{
				if(facebookData.data[i].id in facebook.report.pages)
				{
					facebook.report.pages[facebookData.data[i].id].token = facebookData.data[i].access_token
                    var path = 'insights?metric=page_fans,page_impressions,'+
                    'page_impressions_unique,page_impressions_paid_unique,page_impressions_organic_unique_v2,'+
                    'page_post_engagements,page_consumptions&ids='+facebookData.data[i].id+
					'&since='+(config.currentDate+86400)+'&until='+(config.now+86400)+
					'&access_token='+facebookData.data[i].access_token;

					config.countRequests++;
					facebook.methods.request( path , facebook.methods.getLikesPage );
				}
			}
            
			if(facebookData.paging.next)
			{
				config.countRequests++;
				facebook.methods.request( facebookData.paging.next , facebook.methods.getPagesToken );
			}
			else
			{
				var pagesNotAdmin = [];

				for(var pageId in facebook.report.pages)
				{
					if(facebook.report.pages[pageId].token == 0)
					{
						pagesNotAdmin.push(pageId);
					}
                }
                
                // console.log(pagesNotAdmin);

				//get pages that client is not admin
				if(pagesNotAdmin.length > 0)
				{
					facebook.report.pagesNotAdmin = pagesNotAdmin;

					pagesNotAdmin.forEach(function(pageId){
						var path = '/'+pageId+'/?fields=fan_count';

						config.countRequests++;
						facebook.methods.request( path, facebook.methods.getNotAdminFans );
					})
				}
			}
		},

        getLikesPage: function(facebookData){
            var that = this;
            var likePerPage = {};
            var pagesNotAdmin = [];
            var endTime ='';
            // for each page that returned
            for( var pageId in facebookData  )
            {
                if( 'data' in facebookData[pageId] && facebookData[pageId].data.length>0 )
                {
					// console.log(facebookData[pageId].data)
					var info = [];
					for (var i = facebookData[pageId].data.length - 1; i >= 0; i--) {
						if(facebookData[pageId].data[i].period == 'lifetime' || facebookData[pageId].data[i].period == 'days_28')
                            info[facebookData[pageId].data[i].name] = facebookData[pageId].data[i].values[0];
                            
                        if(facebookData[pageId].data[i].name == 'page_fans')
                            info[facebookData[pageId].data[i].name] = facebookData[pageId].data[i].values[0];
					}

                    var dayLikes = info['page_fans'].value;

                    // Get the data from that page and check if there is data
                    // totalPageFans[ pageId ] += dayLikes
					// console.log(info);
					report.pages[ pageId ].fans = info['page_fans'].value
					report.pages[ pageId ].impressions = info['page_impressions'].value
					report.pages[ pageId ].involvement = info['page_post_engagements'].value
                    report.pages[ pageId ].interactions = info['page_consumptions'].value
                    report.pages[ pageId ].reach = info['page_impressions_unique'].value
					report.pages[ pageId ].reachPaid = info['page_impressions_paid_unique'].value
                    report.pages[ pageId ].reachUnpaid = info['page_impressions_organic_unique_v2'].value

					//add interactions

                    //likePerPage
                    endTime = info['page_fans'].end_time;
                    likePerPage[pageId] = dayLikes;
                }
                else
                {
                    pagesNotAdmin.push(pageId);
                }
            }

            facebook.methods.savePageFans(likePerPage, endTime);

            //get pages that client is not admin
			// if(pagesNotAdmin.length > 0)
			// {
			// 	facebook.report.pagesNotAdmin = pagesNotAdmin;
			// 	var path = '/insights/page_fans_country/lifetime?ids='+pagesNotAdmin.join(',')+
			// 	'&access_token='+fbToken;
			// 	config.countRequests++;
			// 	facebook.methods.request( path, facebook.methods.getNotAdminFans );
			// }

            // Call the functions responsable for catch
            // data from the posts
            for( var pageId in pages )
            {
                var path = '/'+ pageId+'/posts?fields=from,id,story,'+
						'message,picture,likes.limit(1).summary(true),'+
						'comments.limit(1).summary(true),shares,created_time,'+
						'reactions.type(LIKE).summary(total_count).limit(0).as(like),'+
						'reactions.type(LOVE).summary(total_count).limit(0).as(love),'+
						'reactions.type(WOW).summary(total_count).limit(0).as(wow),'+
						'reactions.type(HAHA).summary(total_count).limit(0).as(haha),'+
						'reactions.type(SAD).summary(total_count).limit(0).as(sad),'+
						'reactions.type(ANGRY).summary(total_count).limit(0).as(angry),'+
						'permalink_url&include_hidden=true&limit=50&since='+
                        config.since+'&until='+config.until+
                        '&access_token='+report.pages[ pageId ].token;
                //zera o total de posts
                report.pages[pageId].totalPosts = 0;

                config.countRequests++;
                facebook.methods.request( path, facebook.methods.getPostsPage );

            }
			// setTimeout(function(){
			// 	for( var pageId in pages )
	        //     {
			// 		var path = '/'+ pageId+'/promotable_posts?fields=from,id,type,story,link,message,picture,likes.limit(1).summary(true),comments.limit(1).summary(true),shares,created_time,permalink_url'+
			// 		'&include_inline=true&include_hidden=true&limit=50&since='+
			// 		config.since+'&until='+config.until+
			// 		'&access_token='+fbToken;
			//
			// 		config.countRequests++;
			// 		facebook.methods.request( path, facebook.methods.getPromotablePostsPage );
			// 	}
			// },'350');
        },

        getPostsPage: function(facebookData){
            // If there is posts on the page this month
            var postIds = [],
                pageId = null;
            if( ('data' in facebookData) && facebookData.data.length > 0 )
            {
                // Get shares, likes and comments from each post
                var posts = facebookData.data,
                    objectId = null,
                    pageId = facebookData.data[0].id.replace(/_[0-9]+/,''),
                    validPost = true;

                report.postsPerPage[pageId] = posts.concat(report.postsPerPage[pageId]);
                for ( var i in posts )
                {
                    var currentPost = posts[i];

                    if( ('story' in currentPost) && currentPost.story.indexOf('new photos to the album')>-1 ){

                        objectId = currentPost.link.replace(/[a-zA-Z0-9.\/:_-]+a\.([0-9]+)\.[a-zA-Z0-9\/?=.]+/, '$1');
                        currentPost.id = pageId+'_'+objectId;
                        currentPost.object_id = objectId;
                        currentPost['link'] = 'https://www.facebook.com/'+pageId+'/posts/'+objectId;

                        var related = {};
                        related.id = pageId+'_'+objectId;
                        related.parent_id = currentPost.id;
                        facebook.report.postsRelated.push(related);

                        //add to posts
                        facebook.report.postsPerPage[pageId].push(currentPost);
                    }
                    else
                    {
                        if(('link' in currentPost) && currentPost.link.indexOf('https://www.facebook.com/') !== -1)
                        {
                            // corrige erro para o post que adiciona várias fotos
                            // ele nao pode ser tratado como related, pois o related vai pegar apenas as metricas
                            // da primeira foto
                            var notnewphotos = true;
                            if( ('story' in currentPost) && currentPost.story.indexOf('new photos') !== -1)
                                notnewphotos = false;

                            if(notnewphotos)
                            {
                                // var link_id = currentPost.link.replace(/[a-zA-Z0-9.\/:_-]+a\.[0-9.]+\/([0-9]+)\/[a-zA-Z0-9\/?=.]+/, '$1');
								var full_link = currentPost.link;

                                var link_id = full_link.replace(/[a-zA-Z0-9.\/:_-]+\/([0-9]+)\//, '$1');
                                    link_id = link_id.replace('?type=3','');

                                var full_link_id = pageId+'_'+link_id
                                if(full_link_id != currentPost.id ) // && full_link != link_id
                                {
                                    var related = {};
                                    related.id = full_link_id;
                                    related.parent_id = currentPost.id;
                                    report.postsRelated.push(related);
                                }
                            }
                        }
                    }

                    //agora utiliza o permalink
                    currentPost['link'] = currentPost['permalink_url'];
                    
                    var dateSplited = currentPost['created_time'].split(/[^0-9]+/);
                    var created_at = new Date (dateSplited[0],dateSplited[1]-1,dateSplited[2],dateSplited[3],dateSplited[4],dateSplited[5] );
                    var created_at = pad(created_at.getDate(),2) + '/' + pad(created_at.getMonth()+1,2) + '/' + created_at.getFullYear() + ' ' + pad(created_at.getHours(),2) + ':' + pad(created_at.getMinutes(),2)

                    report.pages[pageId].posts[currentPost.id] = { 
                        likes:0, shares:0, comments:0, view:0, anger:0, haha:0, like:0, love:0, sad: 0, wow:0,
                        engagement: 0, reach: 0, reachPaid: 0, reachUnpaid:0, impressions:0,
                        type: '', story: '', link: currentPost['permalink_url'], message: '', picture: '', created_at: created_at
                     }

                    // Get the consumption data
                    postIds.push( currentPost.id );
                // }
                }
                // console.log(report.pages[pageId]);

                // Only the ids matter
                if( postIds.length>0 )
                {
					if(facebook.report.pages[ pageId ].token != 0 )
					{
						facebook.report.pages[ pageId ].remainingPosts = 1
                        var path =  '/insights?metric='+
                        // 'post_stories_by_action_type,'+
                        // 'post_consumptions_by_type,'+
                        'post_clicks_by_type,'+
                        'post_activity_by_action_type,'+
                        'post_video_views,'+
                        'post_impressions,post_reactions_by_type_total,'+
                        'post_impressions_paid_unique,'+
                        'post_impressions_organic_unique,'+
                        'post_impressions_unique'+
						'&ids='+postIds.join(',')+
						'&access_token='+facebook.report.pages[ pageId ].token;
						config.countRequests++;
						facebook.methods.request( path, facebook.methods.getPostConsumption )
                    }
                }
            }
			// console.log(facebookData);
            if('paging' in facebookData && 'next' in facebookData.paging)
            {
                config.countRequests++;
                facebook.methods.request( facebookData.paging.next + '&since='+config.since, facebook.methods.getPostsPage );
            }

            setTimeout(facebook.methods.checkRequests,5000);
        },

		getPromotablePostsPage: function(facebookData){
			var postIds = [],
                pageId = null;

			if( ('data' in facebookData) && facebookData.data.length > 0 )
            {
                // Get shares, likes and comments from each post
                var posts = facebookData.data,
                    objectId = null,
                    pageId = facebookData.data[0].id.replace(/_[0-9]+/,''),
					reportPosts = report.postsPerPage[pageId],
					details,
                    validPost = true;

                for ( var i in posts )
                {
                    var currentPost = posts[i];

					details = _.where(reportPosts,{message:currentPost['message']});
                    details = details[0];

					if(!details)
					{
						report.postsPerPage[pageId].push(currentPost);
						// console.log(report.postsPerPage[pageId]);
						if( ('story' in currentPost) && currentPost.story.indexOf('new photos to the album')>-1 ){

                            objectId = currentPost.link.replace(/[a-zA-Z0-9.\/:_-]+a\.([0-9]+)\.[a-zA-Z0-9\/?=.]+/, '$1');
                            currentPost.id = pageId+'_'+objectId;
                            currentPost.object_id = objectId;
                            currentPost['link'] = 'https://www.facebook.com/'+pageId+'/posts/'+objectId;

                            var related = {};
                            related.id = pageId+'_'+objectId;
                            related.parent_id = currentPost.id;
                            facebook.report.postsRelated.push(related);

                            //add to posts
                            facebook.report.postsPerPage[pageId].push(currentPost);
                        }
                        else
						{
                            if(('link' in currentPost) && currentPost.link.indexOf('https://www.facebook.com/') !== -1)
                            {
                                // corrige erro para o post que adiciona várias fotos
                                // ele nao pode ser tratado como related, pois o related vai pegar apenas as metricas
                                // da primeira foto
                                var notnewphotos = true;
                                if( ('story' in currentPost) && currentPost.story.indexOf('new photos') !== -1)
                                    notnewphotos = false;

                                if(notnewphotos)
                                {
									// console.log('ei')
                                    // var link_id = currentPost.link.replace(/[a-zA-Z0-9.\/:_-]+a\.[0-9.]+\/([0-9]+)\/[a-zA-Z0-9\/?=.]+/, '$1');
                                    var link_id = currentPost.link.replace(/[a-zA-Z0-9.\/:_-]+\/([0-9]+)\//, '$1');
                                        link_id = link_id.replace('?type=3','');

                                    var full_link_id = pageId+'_'+link_id
									// console.log(full_link_id)
                                    if(full_link_id != currentPost.id)
                                    {
                                        var related = {};
                                        related.id = full_link_id;
                                        related.parent_id = currentPost.id;
                                        report.postsRelated.push(related);
                                    }
                                }
                            }
                        }

						//agora troca o link pelo permalink
                        currentPost['link'] = currentPost['permalink_url'];

                        // Get the consumption data
                        postIds.push( currentPost.id );
					}
				}

				// Only the ids matter
                if( postIds.length>0 )
                {
                    facebook.report.pages[ pageId ].remainingPosts = 1
                    var path =  '/insights?metric='+
                                // 'post_stories_by_action_type,'+
                                // 'post_consumptions_by_type,'+
                                'post_clicks_by_type,'+
                                'post_activity_by_action_type,'+
                                'post_video_views,'+
								'post_impressions,'+
                                'post_impressions_paid_unique,'+
                                'post_impressions_organic_unique,'+
                                'post_impressions_unique'+
								'&ids='+postIds.join(',')+
                                '&access_token='+fbToken;
                    config.countRequests++;
                    facebook.methods.request( path, facebook.methods.getPostConsumption )
                }

				if(facebookData.paging)
	            {
	                config.countRequests++;
	                facebook.methods.request( facebookData.paging.next + '&since='+config.since, facebook.methods.getPromotablePostsPage );
	            }

			}

			facebook.methods.checkRequests();
		},

		getPostConsumption: function(facebookData){
            // post_stories_by_action_type: tab3 excell facebook
            // - Lifetime: The number of stories created about your Page post, by action type. (Total Count)
            // post_consumptions_by_type: tab4 excel facebook
            // - Lifetime: The number of people who clicked anywhere in your post, by type. Clicks generating stories are included in "Other Clicks." (Unique Users)

            var postIds = Object.keys(facebookData),
                pageId = postIds[0].replace(/_[0-9]+/,''),
                posts = report.postsPerPage[pageId],
                details;

            if( postIds.length>0 &&  pageId != 'error')
            {
                // get insights from related posts
                var relatedPostIds = []
                for(var index in report.postsRelated)
                {
                    relatedPostIds.push(report.postsRelated[index].id);
                }

                for( var post in facebookData )
                {
                    //detalhes do posts
                    details = _.where(posts,{id:post});
                    details = details[0];

					// var excluded = _.where(posts_hidden,{post_id:post).length;
                    
					if(details){
	                    var relation = _.findWhere(report.postsRelated, {parent_id: post});

	                    report.pages[pageId].posts[post] = { 
                            likes:0, shares:0, comments:0, view:0, anger:0, haha:0, like:0, love:0, sad: 0, wow:0,
                            engagement: 0, reach: 0, reachPaid: 0, reachUnpaid:0, impressions:0,
                            type: '', story: '', link: '', message: '', picture: ''
                        }

	                    if( 'data' in facebookData[post] && facebookData[post].data.length>0)
	                    {
	                        //loop to get all results
	                        var info = [];
	                        for (var i = facebookData[post].data.length - 1; i >= 0; i--) {
	                            if(facebookData[post].data[i].period == 'lifetime')
	                                info[facebookData[post].data[i].name] = facebookData[post].data[i].values[0];
                            }
                            
                            //se não tem impressions, o post é inválido
							if(!info['post_impressions'].value)
							{
								delete report.pages[pageId].posts[post];
							}
							else
							{
                                // Get the likes, shares and comments
                                // console.log(info['post_activity_by_action_type'])
								var values = info['post_activity_by_action_type'].value
								if( 'like' in values ){
									report.pages[pageId].posts[post].likes = parseInt(values.like);
								}
								else
								report.pages[pageId].posts[post].likes = 0;

								if( 'share' in values ){
									report.pages[pageId].posts[post].shares = parseInt(values.share);
								}
								else
								report.pages[pageId].posts[post].shares = 0;

								if( 'comment' in values ){
									report.pages[pageId].posts[post].comments = parseInt(values.comment);
								}
								else
                                report.pages[pageId].posts[post].comments = 0;
                                
                                //reactions
                                // Get the reactions
                                var values = info['post_reactions_by_type_total'].value;
                                if('anger' in values)
                                    report.pages[pageId].posts[post].anger = parseInt(values.anger);
                                else
                                    report.pages[pageId].posts[post].anger = 0;
                                
                                if('haha' in values)
                                    report.pages[pageId].posts[post].haha = parseInt(values.haha);
                                else
                                    report.pages[pageId].posts[post].haha = 0;

                                if('like' in values)    
                                    report.pages[pageId].posts[post].like = parseInt(values.like);
                                else
                                    report.pages[pageId].posts[post].like = 0;

                                if('love' in values)    
                                    report.pages[pageId].posts[post].love = parseInt(values.love);
                                else
                                    report.pages[pageId].posts[post].love = 0;

                                if('sad' in values)      
                                    report.pages[pageId].posts[post].sad = parseInt(values.sorry);
                                else
                                    report.pages[pageId].posts[post].sad = 0;

                                if('wow' in values)
                                    report.pages[pageId].posts[post].wow = parseInt(values.wow);
                                else
                                    report.pages[pageId].posts[post].wow = 0;


                                // video views
								if( 'post_video_views' in info && details.type == 'video')
								{
                                    report.pages[pageId].posts[post].view = parseInt(info['post_video_views'].value)
								}
								else{
									// Get the links_clicks, other_clicks and photo_view values - post_consumptions_by_type
									values = info['post_clicks_by_type'].value
									if( 'photo view' in values )
									{
                                        report.pages[pageId].posts[post].view = parseInt(values['photo view']);
									}
									else if( 'link clicks' in values )
									{
										report.pages[pageId].posts[post].view = parseInt(values['link clicks']);
									}
									else{
										report.pages[pageId].posts[post].view = 0;
									}
								}

                                //reach
								report.pages[pageId].posts[post].reach = parseInt(info['post_impressions_unique'].value);
								report.pages[pageId].posts[post].reachPaid = parseInt(info['post_impressions_paid_unique'].value);
                                report.pages[pageId].posts[post].reachUnpaid = parseInt(info['post_impressions_organic_unique'].value);


								//impressions
								var impressions = info['post_impressions'].value
								report.pages[pageId].posts[post].impressions = parseInt(impressions);

								// Calculate the engagement of the users per post
								var engagement = facebook.methods.calculateEngagement(report.pages[pageId].posts[post]);
								report.pages[pageId].posts[post].engagement = engagement;
								report.pages[pageId].totalEngagement += engagement; // não sei para q serve

								var dateSplited = details.created_time.split(/[^0-9]+/);
								var created_at = new Date (dateSplited[0],dateSplited[1]-1,dateSplited[2],dateSplited[3],dateSplited[4],dateSplited[5] );
								var created_at = pad(created_at.getDate(),2) + '/' + pad(created_at.getMonth()+1,2) + '/' + created_at.getFullYear() + ' ' + pad(created_at.getHours(),2) + ':' + pad(created_at.getMinutes(),2)

								report.pages[pageId].posts[post].type = details.type
								report.pages[pageId].posts[post].story = details.story
								report.pages[pageId].posts[post].link = details.link
								report.pages[pageId].posts[post].message = details.message
								report.pages[pageId].posts[post].picture = details.picture
								report.pages[pageId].posts[post].created_at = created_at

								report.pages[pageId].posts[post].page_id = pageId
								report.pages[pageId].posts[post].post_id = post

								report.pages[pageId].posts[post].admin = 1;
                                report.pages[pageId].admin = 1;
							}
	                    }
	                    else
	                    {
	                        if(details.comments)
	                           report.pages[pageId].posts[post].comments = details.comments.summary.total_count
	                        else
	                           report.pages[pageId].posts[post].comments = 0;

	                        if(details.likes)
	                           report.pages[pageId].posts[post].likes = details.likes.summary.total_count
	                        else
	                           report.pages[pageId].posts[post].likes = 0;

	                        if(details.shares)
	                           report.pages[pageId].posts[post].shares = details.shares.count
	                        else
	                           report.pages[pageId].posts[post].shares = 0;

	                        report.pages[pageId].posts[post].view = 0;
	                        report.pages[pageId].posts[post].anger = 0;
	                        report.pages[pageId].posts[post].haha = 0;
	                        report.pages[pageId].posts[post].like = 0;
	                        report.pages[pageId].posts[post].love = 0;
	                        report.pages[pageId].posts[post].sad = 0;
	                        report.pages[pageId].posts[post].wow = 0;

	                        report.pages[pageId].posts[post].engagement = 0;

	                        report.pages[pageId].posts[post].reach = 0;
	                        report.pages[pageId].posts[post].reachPaid = 0;
	                        report.pages[pageId].posts[post].reachUnpaid = 0;

							//impressions
	                        report.pages[pageId].posts[post].impressions = 0;

	                        report.pages[pageId].posts[post].type = details.type
	                        report.pages[pageId].posts[post].story = details.story
	                        report.pages[pageId].posts[post].link = details.link;
	                        report.pages[pageId].posts[post].message = details.message;
	                        report.pages[pageId].posts[post].picture = details.picture;
	                        report.pages[pageId].posts[post].created_at = created_at;

	                        report.pages[pageId].posts[post].page_id = pageId;
	                        report.pages[pageId].posts[post].post_id = post;

	                        report.pages[pageId].posts[post].admin = 1;
	                        report.pages[pageId].admin = 1;
	                    }
					}
				}


                // Check if it's not empty
                facebook.report.pages[pageId].totalPosts = Object.keys(report.pages[pageId].posts).length;


                if(relatedPostIds.length>0)
                {
					// console.log(relatedPostIds.join(','));
                    var path =  '/insights?metric='+
                                // 'post_stories_by_action_type,'+
                                // 'post_consumptions_by_type,'+
                                'post_clicks_by_type,'+
                                'post_activity_by_action_type,'+
                                'post_video_views,'+
								'post_impressions,'+
                                'post_impressions_paid_unique,'+
                                'post_impressions_organic_unique,'+
                                'post_impressions_unique'+
								'&ids='+relatedPostIds.join(',')+
                                '&access_token='+facebook.report.pages[ pageId ].token;
                    config.countRequests++;
                    facebook.methods.request( path, facebook.methods.getRelatedPostConsumption )
                }

                facebook.methods.checkRequests();
            }
        },

        getRelatedPostConsumption: function(facebookData){
            var postIds = Object.keys(facebookData),
                details;
            
            if( postIds.length>0 )
            {
                for( var post in facebookData )
                {
                    var pageId = post.replace(/_[0-9]+/,'');
                    var posts = report.postsPerPage[pageId];

                    if( 'data' in facebookData[post] && facebookData[post].data.length>0 )
                    {
                        var done = false;
                        var relation = _.findWhere(report.postsRelated, {id: post});

                        details = _.where(posts,{id:relation.parent_id});
                        details = details[0];

                        done = _.indexOf(report.relatedDone,relation.parent_id);
						// var excluded = _.where(posts_hidden,{post_id:post).length;

                        if(report.pages[pageId].posts[relation.parent_id] && done == -1)
                        {
                            //loop to get all results
                            var info = [];
                            for (var i = facebookData[post].data.length - 1; i >= 0; i--) {
                                if(facebookData[post].data[i].period == 'lifetime')
                                    info[facebookData[post].data[i].name] = facebookData[post].data[i].values[0];
                            }

                            // Get the likes, shares and comments
                            var values = info['post_activity_by_action_type'].value

                            if( 'like' in values ){
                                report.pages[pageId].posts[relation.parent_id].likes = parseInt(values.like);
                            }

                            if( 'share' in values )
                                report.pages[pageId].posts[relation.parent_id].shares = parseInt(values.share);

                            if( 'comment' in values )
                                report.pages[pageId].posts[relation.parent_id].comments = parseInt(values.comment);


                            if( 'post_video_views' in info && details.type == 'video')
                            {
                                report.pages[pageId].posts[relation.parent_id].view = info['post_video_views'].value
                            }
                            else{
                                // // Get the links_clicks, other_clicks and photo_view values - post_consumptions_by_type
                                values = info['post_clicks_by_type'].value
                                if( 'photo view' in values )
                                {
                                    report.pages[pageId].posts[relation.parent_id].view = parseInt(values['photo view']);
                                }
                                else if( 'link clicks' in values )
                                {
                                    report.pages[pageId].posts[relation.parent_id].view = parseInt(values['link clicks']);
                                }
                                else{
                                    report.pages[pageId].posts[relation.parent_id].view = 0;
                                }
                            }

                            //reach
                            report.pages[pageId].posts[relation.parent_id].reach = parseInt(info['post_impressions_unique'].value);
                            report.pages[pageId].posts[relation.parent_id].reachPaid = parseInt(info['post_impressions_paid_unique'].value);
                            report.pages[pageId].posts[relation.parent_id].reachUnpaid = parseInt(info['post_impressions_organic_unique'].value);


							//impressions
	                        var impressions = info['post_impressions'].value
	                        report.pages[pageId].posts[relation.parent_id].impressions = parseInt(impressions);

                            report.pages[pageId].posts[relation.parent_id].admin = 1;
                            report.pages[pageId].admin = 1;

                            // ReCalculate the engagement of the users per post
                            var engagement = facebook.methods.calculateEngagement(report.pages[pageId].posts[relation.parent_id]);
                            report.pages[pageId].posts[relation.parent_id].engagement = engagement;

                            report.relatedDone.push(relation.parent_id);
                        }

                    }
                }
            }

            facebook.methods.checkRequests();
        },

        checkRequests: function(){
            // console.log(': '+config.countRequests)
            if( !config.countRequests )
            {
                // organize pages posts not admin
                facebook.methods.organizeNotAdminPosts();

                facebook.config.callback();
            }
        },

        savePageFans: function(json,endTime){

            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);

            var formatedTomorrow = tomorrow.getFullYear() + '-' + pad((tomorrow.getMonth()+1),2) + '-' + pad((tomorrow.getDate()),2);
            var endTime = endTime.substr(0,10);

            // console.log(formatedTomorrow)
            // console.log(endTime)
            if(formatedTomorrow == endTime)
            {
                $.post(rootURL+'pages/savefans',json,function(data){

                })
            }
        },

        getNotAdminFans: function(facebookData){

            var pageId = null;
            var likePerPage = {};

			if('fan_count' in facebookData)
			{
				report.pages[ facebookData.id ].fans = facebookData.fan_count;
	            likePerPage[ facebookData.id ] = facebookData.fan_count;
			}
			$.post(rootURL+'pages/savefans',likePerPage)

			var path = '/'+ facebookData.id+'/posts?fields=from,id,story,'+
					'message,picture,likes.limit(1).summary(true),'+
					'comments.limit(1).summary(true),shares,created_time,'+
					'permalink_url&include_hidden=true&limit=50&since='+
					config.since+'&until='+config.until+
					'&access_token='+fbToken;
			//zera o total de posts
			report.pages[facebookData.id].totalPosts = 0;

			config.countRequests++;
			facebook.methods.request( path, facebook.methods.getPostsPage );
        },

        organizeNotAdminPosts: function(){
            var pagesNotAdmin = facebook.report.pagesNotAdmin,
                details;

            for(var i in pagesNotAdmin)
            {
                var pageId = pagesNotAdmin[i];
                var posts = report.postsPerPage[pageId];
                // console.log(posts)
                for(var i in posts)
                {
                    var details = posts[i];
                    if(details != undefined)
                    {
                        var postId = details.id;

                        if(report.pages[pageId].posts[postId])
                        {
                            if('comments' in details)
                               report.pages[pageId].posts[postId].comments = details.comments.summary.total_count
                            else
                               report.pages[pageId].posts[postId].comments = 0;

                            if(details.likes)
                               report.pages[pageId].posts[postId].likes = details.likes.summary.total_count
                            else
                               report.pages[pageId].posts[postId].likes = 0;

                            if(details.shares)
                               report.pages[pageId].posts[postId].shares = details.shares.count
                            else
                               report.pages[pageId].posts[postId].shares = 0;


                            report.pages[pageId].posts[postId].view = 0;

                            report.pages[pageId].posts[postId].anger = 0;
                            report.pages[pageId].posts[postId].haha = 0;
                            report.pages[pageId].posts[postId].like = 0;
                            report.pages[pageId].posts[postId].love = 0;
                            report.pages[pageId].posts[postId].sad = 0;
                            report.pages[pageId].posts[postId].wow = 0;

                            var engagement = facebook.methods.calculateEngagement(report.pages[pageId].posts[postId]);
                            report.pages[pageId].posts[postId].engagement = engagement;

                            // report.pages[pageId].posts[postId].engagement = 0;

                            report.pages[pageId].posts[postId].reach = 0;
                            report.pages[pageId].posts[postId].reachPaid = 0;
							report.pages[pageId].posts[postId].reachUnpaid = 0;

                            report.pages[pageId].posts[postId].impressions = 0;

                            report.pages[pageId].posts[postId].type = details.type
                            report.pages[pageId].posts[postId].link = details.link
                            report.pages[pageId].posts[postId].story = details.story
                            report.pages[pageId].posts[postId].message = details.message
                            report.pages[pageId].posts[postId].picture = details.picture

                            var dateSplited = details.created_time.split(/[^0-9]+/);
                            var created_at = new Date (dateSplited[0],dateSplited[1]-1,dateSplited[2],dateSplited[3],dateSplited[4],dateSplited[5] );
                            var created_at = pad(created_at.getDate(),2) + '/' + pad(created_at.getMonth()+1,2) + '/' + created_at.getFullYear() + ' ' + pad(created_at.getHours(),2) + ':' + pad(created_at.getMinutes(),2)

                            report.pages[pageId].posts[postId].created_at = created_at;

                            report.pages[pageId].posts[postId].admin = 0;
                            report.pages[pageId].admin = 0;
                        }

                    }

                }

                facebook.report.pages[pageId].totalPosts = Object.keys(report.pages[pageId].posts).length;
            }
        },

        saveInstagram: function(){
            var ids = [];

            for( var i in pages )
            {
                var path = '/instagram_accounts?ids='+pages[i].facebook_id+'&fields=username,id';

                config.countRequests++;
                facebook.methods.request( path , facebook.methods.getInstagramPage );
            }
        },

        getInstagramPage: function(facebookData){
            var data = {};
            // console.log(facebookData)
            for( var pageId in facebookData  )
            {
                if(pageId != 'error' && facebookData[pageId].data.length > 0)
                {
                    data[pageId] = facebookData[pageId].data[0].username;
                }
            }
            $.post(rootURL+'pages/saveinstagram',data,function(data){

            })
        },

        calculateEngagement: function(data){

            if(parseInt(client_id) == 2)
            {
                var engagement = data.shares + // media shares
                        data.likes + // media likes
                        (data.comments /2) + // media comments/ 2
                        (data.view /2) // media views/2

                return (Math.round(engagement * 100) / 100).toFixed(2);
            }
            else
            {
                var engagement = data.shares + data.likes + data.comments;

                return (Math.round(engagement * 100) / 100).toFixed(2);
            }

        }
	}

	return {methods : methods, report : report, config: config};
}();

facebook.methods.init();
