var tables = function(){
	'use strict';
	
	var tables = {
		getInitialDate: function(){
			var today = new Date();
			var dd = today.getDate();
			var mm = today.getMonth()+1; //January is 0!
			var yyyy = today.getFullYear();
			
			if(dd<10) {
				dd='0'+dd
			} 

			if(mm<10) {
				mm='0'+mm
			} 

			today = dd+'/'+mm+'/'+yyyy;			
		},
		dataPicker: function(){
			tables.getInitialDate();
			
			$('.date').datepicker({
				format: 'dd/mm/yyyy',
				todayBtn: true,
				todayHighlight: true,
				autoclose: true
			});
		},
		tableRelatorio: function(section, order){
			if(order === undefined) order = 1;
			if(section)
			{
				var t = $('.table-relatorio').DataTable({
					"oLanguage": {"sZeroRecords": "", "sEmptyTable": ""},
					searching: false,
					paging: false,
					info: false
					// ordering: false
				});
			}
			else
			{
				var t = $('.table-relatorio').DataTable({
					searching: false,
					//"order": [[ order, "desc" ]],
					"oLanguage": {"sZeroRecords": "", "sEmptyTable": ""}
				});
			}

			t.on( 'order.dt search.dt', function () {
                t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                    cell.innerHTML = i+1;
                } );
            } ).draw();

			tables.dataPicker();

			$('.dataTables_length').appendTo('.search-date');
			$('.dataTables_info, .dataTables_paginate').appendTo('.table-pagination');
			


			$('.table-page th').each(function(index, el) {
				var widthTh = $(el).width();
				$('.table-total th').eq(index).width(widthTh);
			});

			setTimeout(function(){
				$('.table-relatorio').floatThead({
					position: 'absolute'
				});
				$('.dataTables_length select').customSelect();
			},900)
		},
		print: function(){
			$('.inner').on('click', '.bt-print', function(event) {
				event.preventDefault();
				window.print();
				// $('body').hide().show(0);
				// $('*[style]').attr('style', '');
				// $("body").print({
				// 	rejectWindow : true,
				// 	noPrintSelector : ".no-print",
				// 	mediaPrint : true
				// });
			});
		},
		profiles: function(){
			$('.bt-profiles').click(function(event) {
				event.preventDefault();
				$('body').addClass('change-profiles');

				setTimeout(function() {
					window.location = rootURL;
				}, 1500);
			});
		},
		events: function(){
			tables.profiles();

			$('.bt-nav').click(function(event) {
				event.preventDefault();
				$('header').toggleClass('open-nav');
			});

			window.onload = function() {
				setTimeout(function() {
					$('.inner').removeClass('page-loading');
				}, 1500);
			};

			tables.print();
		},
		init: function(){
			tables.events();
			tables.tableRelatorio();
		}
	}

	return tables;
}();

// tables.init();