var graphapi_instagram = function(){
    'use strict';
    var since, until;
    var fb_accounts = [];
    var medias = [];
    var video_ids = [];
    var video_views = [];

    var graphapi_instagram = {

        init: function(){
            // instagram.getInstagramIds();
            graphapi_instagram.getPageInsights();
            graphapi_instagram.initReport();
        },

        getPermissions: function()
        {
            var path = '/me/permissions?access_token='+fbToken;

            facebook.config.countRequests++;
            facebook.methods.request( path , graphapi_instagram.checkPermissions );            
        },

        checkPermissions: function(facebookData)
        {
            var permissoes = [  'read_insights',
                                'manage_pages',
                                'pages_show_list',
                                'business_management',
                                'instagram_basic',
                                'instagram_manage_insights',
                                'public_profile'
                            ];
            
            for(var i in facebookData.data)
            {
                var index = permissoes.indexOf(facebookData.data[i].permission);
                if( index !== -1)
                {
                    if(facebookData.data[i].status == 'granted')
                    {
                        permissoes.splice(index,1);
                    }
                }
            }

            if(permissoes.length > 0)
            {
                fbToken = facebook.methods.accessToken
            }

            graphapi_instagram.getInstagramIds();
        },

        getInstagramIds: function()
        {
            var path = '/me/accounts?fields=instagram_business_account,access_token,name,id&access_token='+fbToken;

            facebook.config.countRequests++;
            facebook.methods.request( path , graphapi_instagram.collectInstagramIds );
        },

        collectInstagramIds: function(facebookData)
        {
            if(facebookData.data.length > 0)
            {
                fb_accounts = fb_accounts.concat(facebookData.data);
    
                if(facebookData.paging.next)
                {
                    facebook.config.countRequests++;
                    facebook.methods.request( facebookData.paging.next , graphapi_instagram.collectInstagramIds );
                }
                else
                {
                    graphapi_instagram.saveInstagramIds();
                }
            }
        },

        saveInstagramIds: function(){

            var  instagrams_ids = [];

            for(var i in fb_accounts)
			{
				if('instagram_business_account' in fb_accounts[i])
				{
                    instagrams_ids.push({fb_id: fb_accounts[i].id, instagram_id:fb_accounts[i].instagram_business_account.id})
                }
            }

            $.post(rootURL+'instagram/save-bussines-id',{ instagrams: instagrams_ids },function(response){
                // console.log(response);
            })
        },

        initReport: function()
        {
            for (var id in pages)
            {
                var path = id+'/media?fields=id,caption,media_url,media_type,permalink,'+
                            'comments_count,thumbnail_url,like_count,timestamp,owner,username,'+
                            'media_product_type,' +
                            'insights.metric(reach,total_interactions,impressions,saved)&limit=25'+
                            '&access_token='+fbToken
                facebook.config.countRequests++;
                facebook.methods.request( path , graphapi_instagram.collectMedia );
            }
        },

        collectMedia: function(facebookData){

            if(facebookData.error)
            {
                graphapi_instagram.collectVideos();
                return true;
            }

            if(facebookData.data.length > 0)
            {
                medias = medias.concat(facebookData.data);

                var today = new Date();
                var year = new Date(today.getFullYear()-1,7,1);        
                var lastpost = new Date(medias[medias.length-1].timestamp);
    
                if(year.getTime() < lastpost.getTime())
                {
                    if(facebookData.paging.next)
                    {
                        facebook.config.countRequests++;
                        facebook.methods.request( facebookData.paging.next , graphapi_instagram.collectMedia );
                    }
                }
                graphapi_instagram.saveMediaInsights(facebookData.data);
            }
            else
            {
                graphapi_instagram.collectVideos();
            }


        },

        saveMediaInsights: function(data){
            $.post(rootURL+'instagram/save-medias',{ medias: data },function(response){
                if(facebook.config.countRequests == 0)
                    graphapi_instagram.collectVideos();
            })
        },

        getPageInsights: function(){
            for (var id in pages)
            {
                var path = id+'?fields=id,ig_id,followers_count,follows_count,'+
                            'username,profile_picture_url,name'+
                            '&access_token='+fbToken
                facebook.config.countRequests++;
                facebook.methods.request( path , graphapi_instagram.savePage );
            }
        },

        savePage: function(facebookData){
            $.post(rootURL+'instagram/save-page',{ page: facebookData },function(response){
                // console.log(response);
                if(facebook.config.countRequests == 0)
                    graphapi_instagram.collectVideos();
            })
        },

        collectVideos: function(){

            for(var i in medias)
            {
                if (
                    medias[i].media_type == 'VIDEO' && 
                    medias[i].media_product_type != 'REELS'
                    )
                {
                    video_ids.push(medias[i].id); 
                }
            }

            graphapi_instagram.getVideoViews();
        },

        getVideoViews: function()
        {
            //só pode enviar 50 ids por requisição
            if(video_ids.length)
            {
                var i,j,temparray,chunk = 50;
                for (i=0,j=video_ids.length; i<j; i+=chunk) {
                    temparray = video_ids.slice(i,i+chunk);
                    
                    var ids_string = temparray.join(',');
                    var path = 'insights?ids='+ids_string+'&metric=video_views'+
                                    '&access_token='+fbToken
                        facebook.config.countRequests++;
                        facebook.methods.request( path , graphapi_instagram.collectVideoViews );
                }
            }
            else
            {
                graphapi_instagram.start();
            }
        },

        collectVideoViews: function(facebookData)
        {
            for (var i in facebookData)
            {
                var details = {post_id: i, views: facebookData[i].data[0].values[0].value}
                video_views.push(details);
            }

            if(facebook.config.countRequests == 0)
                graphapi_instagram.saveVideoViews();
        },

        saveVideoViews: function()
        {
            $.post(rootURL+'instagram/save-video-views',{ videos: video_views },function(response){
                graphapi_instagram.start();
            });
        },

        start: function(){
            dashboard.init();
            dashboard.refresh();
        }
    }
    
    return graphapi_instagram;
}();