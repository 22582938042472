var dashboard = function(){
	'use strict';

	var report;
	var screenX = $(document).width() / 2;
	var screenY = $(document).height() / 2;
	var maxData = 0;
	var topCurrent = 0;
	var topNext = 0;
	var keyMax = 'normal';

	var chart;
	var chart1 ;
	var options ;

	var countClick = false;

	var urlCurrent = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1),
		urlReplace = urlCurrent.substring(urlCurrent.lastIndexOf('/') + 1);

	var countMonth = 1;

	var clicked = false;

	var position = 'riba';

	var labelsMoth = [];
	var labelsPorcent = [];

	var metrics = [];
	var metricRequest = 0;

	var isMobile = {
		Android: function() {
			return navigator.userAgent.match(/Android/i);
		},
		BlackBerry: function() {
			return navigator.userAgent.match(/BlackBerry/i);
		},
		iOS: function() {
			return navigator.userAgent.match(/iPhone|iPad|iPod/i);
		},
		iPhone: function() {
			return navigator.userAgent.match(/iPhone/i);
		},
		iPad: function() {
			return navigator.userAgent.match(/iPad/i);
		},
		iPod: function() {
			return navigator.userAgent.match(/iPod/i);
		},
		Opera: function() {
			return navigator.userAgent.match(/Opera Mini/i);
		},
		Windows: function() {
			return navigator.userAgent.match(/IEMobile/i);
		},
		any: function() {
			return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
		},
		any920: function() {
			return (window.innerWidth <= 920);
		},
		any640: function() {
			return (window.innerWidth <= 640 || isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
		}
	};

	var dashboard = {
		toGeo: function(d) {
			return (d * 100) / 360;
		},
		convertPorcent: function(porcent){
			var percentsConvert = (porcent * 100) / maxData;

			var parentHeight = parseInt($('.ranking').height());
			var pixels = parentHeight*(percentsConvert/250);

			return pixels;
		},
		pushState: function(hash){
			var url = {
				data: '',
				title: '/'+hash,
				pathname: '/'+hash
			};

			window.history.pushState(url.data, url.title, url.pathname);
		},
		open: function(hash, report){
			$('body').addClass('loading');
			dashboard.pushState(hash);

			if (hash === 'profiles' || hash === 'dashboard') {
				dashboard.close();
			}
		},
		refresh: function(){
			// $('.data-favorite .rate').addClass('loading');
			$('.data-favorite .ranking').addClass('loading-widget');
			$('.training-tops .top-posts').addClass('loading-widget');
			$('.training-tops .top-ranking').addClass('loading-widget');
			$('.rate .data-porcent strong').html('0');
			$('.graphics-datas').html('');
			$('.graphics-datas').addClass('hide');
			$('.top-posts table tbody').html('');
			$('.top-ranking table tbody').html('');

			$.get(rootURL+'dashboard/engagement',function(data)
			{
				$('.graphics-datas').html('');
				$('.data-favorite .loading-widget').removeClass('loading-widget');
				dashboardEngagement = JSON.parse( data.dashboardEngagement )

				if(dashboardEngagement.length > 0)
					dashboard.setRankingDashboard(dashboardEngagement);

			},'json');

			$.get(rootURL+'dashboard/topposts',function(data)
			{
				$('.top-posts').removeClass('loading-widget');
				$('.top-posts table tbody').html(data)
			});

			if(socialnetwork == 'facebook')
			{
				$.get(rootURL+'dashboard/monthposts',function(data)
				{
					// console.log(data);
					$('.posts-month').html('');
					$('.posts-per-month.loading-widget').removeClass('loading-widget');
					dashboard.setPostsMonth(data);
				},'json');

				dashboard.getPageMetrics();
			}
			else
			{
				$('.posts-month').html('');
				$('.posts-per-month.loading-widget').removeClass('loading-widget');
			}

			//atualiza o ranking para poder mostrar o widget atualizado
			ranking.update();
			// chart.redraw();
		},
		close: function(){
			setTimeout(function() {
				$('body').removeClass('loading');
			}, 800);
		},
		tab: function(){
			var tabCurrent = $('.inner').attr('data-tab');
			$('.tab[data-tab='+tabCurrent+']').addClass('active');
		},
		getPageMetrics: function(){
			var since = new Date(firstDayofTheMonth);

			metrics['impressions'] = [];
			metrics['interactions'] = [];

			// mes atual
			var path = 'insights?metric=page_impressions,page_engaged_users,page_consumptions&ids='+dashboard_fb_id+
						'&since='+(since.getTime()/1000)+'&until='+((today.getTime()/1000)+(86400+2))+
						'&access_token='+facebook.report.pages[ dashboard_fb_id ].token;

			metricRequest++;
			FB.api( path, function(response)
            {
                 metricRequest--;
                 dashboard.organizeMetricsMonth( response );
            });

			// meses passados
			for (var i = 1; i <= 5; i++) {
			   var year = today.getFullYear();
			   var month = today.getMonth()-i;

			   // more statements
			   var FirstDay = new Date(year, month, 1);
			   var LastDay = new Date(year, month + 1, 0);
			   LastDay.setHours(23, 59, 59);

			   var path = 'insights?metric=page_impressions,page_engaged_users,page_consumptions&ids='+dashboard_fb_id+
			   '&since='+(FirstDay.getTime()/1000)+'&until='+((LastDay.getTime()/1000)+(86400+2))+
			   '&access_token='+facebook.report.pages[ dashboard_fb_id ].token;

			   metricRequest++;
			   FB.api( path, function(response)
			   {
				   metricRequest--;
				   dashboard.organizeMetricsMonth( response );
			   });
			}

		},
		organizeMetricsMonth: function(facebookData){
			for( var pageId in facebookData  )
            {
                if( 'data' in facebookData[pageId] && facebookData[pageId].data.length>0 )
                {
					var info = [];
					for (var i = facebookData[pageId].data.length - 1; i >= 0; i--) {
						if(facebookData[pageId].data[i].period == 'lifetime' || facebookData[pageId].data[i].period == 'days_28')
						{
							var last = facebookData[pageId].data[i].values.length - 1;
							if(last > 27)
								last = 27;
							info[facebookData[pageId].data[i].name] = facebookData[pageId].data[i].values[last];
							var positionDate = new Date(facebookData[pageId].data[i].values[0].end_time);
							var positionMonth = positionDate.getMonth();
							var positionYear = positionDate.getFullYear();
						}
					}

					if(positionMonth < 10)
						var position = positionYear+'0'+positionMonth;
					else
						var position = positionYear+''+positionMonth;

					metrics['impressions'][position] = info['page_impressions'].value;
                    metrics['interactions'][position] = info['page_consumptions'].value;
				}
			}
			if(!metricRequest)
			{
				dashboard.setMetricsMonth();
			}
		},
		setRankingDashboard: function(dashboardEngagement){
			var lastPorcent = 0,
				arr = dashboardEngagement.length;

			$.each(dashboardEngagement, function(index, val) {
				if (index === arr - 1) {
					lastPorcent = val.porcent;
				}
			});

			var data = [
				{
					month: dashboardEngagement[0].month,
					porcent: dashboardEngagement[0].porcent
				},
				{
					month: dashboardEngagement[1].month,
					porcent: dashboardEngagement[1].porcent
				},
				{
					month: dashboardEngagement[2].month,
					porcent: dashboardEngagement[2].porcent
				},
				{
					month: dashboardEngagement[3].month,
					porcent: dashboardEngagement[3].porcent
				},
				{
					month: dashboardEngagement[4].month,
					porcent: dashboardEngagement[4].porcent
				}
			];

			options = {
		        chart: {
		        	renderTo: 'graphics-datas',
		            type: 'line'
		        },
		        xAxis: {
		            categories: [data[0].month, data[1].month, data[2].month, data[3].month, data[4].month]
		        },
		        plotOptions: {
		        	series: {
    	                lineWidth: 3,
    	                lineColor: '#666',

	    	            dataLabels: {
	                        enabled: true,
	                        format: '{y}%'
	                    }
    	            },
		            line: {
		                dataLabels: {
		                    enabled: true
		                },
		                enableMouseTracking: false
		            }
		        },
		        series: [{
		            name: 'Expresso clube',
		            data: [data[0].porcent, data[1].porcent, data[2].porcent, data[3].porcent, data[4].porcent],

		            marker: {
		            	symbol: 'url('+assetsRoot+'	images/dot-icon.png)',
		                width: 23,
		                height: 23
		            },
		        }]
		    };

		    $('.graphics-datas').removeClass('hide');
		    chart = new Highcharts.Chart(options);
			// chart1.destroy();
			// chart1 = new Highcharts.Chart(options);
			// chart = chart1; // <-------- Add this line here.

			$('#graphics-datas').highcharts();

			var j = 0;
			if(lastPorcent > 0 )
			{
				var intervalPorcent = setInterval(function() {
					if (j < lastPorcent) {
						j++;
						$('.rate .data-porcent strong').html(j)
					} else{
						clearInterval(intervalPorcent);
					};

				}, 2);
			}
			else
			{
				$('.rate .data-porcent strong').html(lastPorcent.porcent)
			}
		},
		setMetricsMonth: function(){
			$('.metrics-month').html('');
			$('.page-metrics-month.loading-widget').removeClass('loading-widget');


			var months = ['Jan','Feb','Mar','Apr','May', 'Jun','Jul', 'Aug', 'Sep','Oct','Nov','Dec'];

			var graphMonths = [];
			var graphInteractions = [];
			var graphImpressions = [];
			// console.log(metrics['impressions'])
			for (var key in metrics['impressions']) {
				if (metrics['impressions'].hasOwnProperty(key))
				{
					var month = key.substr(4);
					if(month.substr(0,1) == '0')
						month = month.substr(1);

					graphMonths.push(months[month])
					graphInteractions.push(metrics['interactions'][key])
					graphImpressions.push(metrics['impressions'][key])
				}
			}
			Highcharts.chart('metrics-month', {
			    chart: {
			        zoomType: 'xy'
			    },
			    title: {
			        text: 'Metrics'
			    },
			    subtitle: {
			        text: 'Page Metrics per Month'
			    },
			    xAxis: [{
			        categories: graphMonths,
			        crosshair: true
			    }],
			    yAxis: [
					{ // Tertiary yAxis
				        gridLineWidth: 0,
				        title: {
				            text: 'Interactions',
				            style: {
				                color: Highcharts.getOptions().colors[1]
				            }
				        },
				        labels: {
				            format: '{value}',
				            style: {
				                color: Highcharts.getOptions().colors[1]
				            }
				        },
						opposite: true
				    },
					{ // Primary yAxis
				        labels: {
				            format: '{value}',
				            style: {
				                color: Highcharts.getOptions().colors[0]
				            }
				        },
				        title: {
				            text: 'Impressions',
				            style: {
				                color: Highcharts.getOptions().colors[0]
				            }
				        }

				    }
				],
			    tooltip: {
			        shared: true
			    },
			    legend: {
			        layout: 'vertical',
			        align: 'left',
			        x: 80,
			        verticalAlign: 'top',
			        y: 55,
			        floating: true,
			        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
			    },
			    series: [{
			        name: 'Impressions',
			        type: 'spline',
			        yAxis: 1,
			        data: graphImpressions,
			        dashStyle: 'shortdot',
			        tooltip: {
			            valueSuffix: ''
			        }

			    }, {
			        name: 'Interactions',
			        type: 'spline',
			        data: graphInteractions,
			        tooltip: {
			            valueSuffix: ''
			        }
			    }]
			});

			$('#metrics-month').highcharts();
		},
		setPostsMonth: function(posts){

			if(posts)
			{
				var months = posts.months;

				Highcharts.chart('posts-month', {
				    chart: {
				        type: 'column'
				    },
				    title: {
				        text: 'Posts Per Month'
				    },
				    xAxis: {
				        categories: months
				    },
				    yAxis: {
				        min: 0,
				        title: {
				            text: 'Total Posts per Month'
				        },
				        stackLabels: {
				            enabled: true,
				            style: {
				                fontWeight: 'bold',
				                color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
				            }
				        }
				    },
				    legend: {
				        align: 'right',
				        x: -30,
				        verticalAlign: 'bottom',
				        y: 25,
				        floating: true,
				        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
				        borderColor: '#CCC',
				        borderWidth: 1,
				        shadow: false
				    },
					tooltip: {
				        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
				        shared: true
				    },
				    plotOptions: {
				        column: {
				            stacking: 'normal',
				            dataLabels: {
				                enabled: true,
				                color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
				            }
				        }
				    },
				    series: [{
				        name: 'Paid',
				        data: posts.paid
				    }, {
				        name: 'UnPaid',
				        data: posts.unpaid
				    }]
				});

				$('#posts-month').highcharts();
			}
		},
		tabMobile: function(){
			$('.header').addClass('open-tabs');
			// if (!countClick) {
			// 	// $('.header').addClass('open-tabs');

			// 	// setTimeout(function() {
			// 	// 	$('body').addClass('no-scroll');
			// 	// }, 400);
			// 	// var transform = 'translate(0)';


			// 	countClick = true;
			// }else{
			// 	// $('.header').removeClass('open-tabs');
			// 	// $('body').removeClass('no-scroll');

			// 	countClick = false;
			// }
		},
		changeDashboardProfile: function(){
			var id = $(this).val();
			$('body').addClass('loading');

			$.post(rootURL+'dashboard/save',{userPageId:id},function(data){
				$('.tab[data-tab="dashboard"]').trigger('click')
				dashboard_fb_id = data.page_facebook_id;
				dashboard.refresh();
			},'json')
		},
		events: function(){

			FastClick.attach(document.body);

			$('.dashboard').on('change','.list-options ',dashboard.changeDashboardProfile)

			$(window).resize(function(event) {
				var widthLine = $('.line-porcent').width();

				$('svg').each(function(index, el) {
					$(this).find('line').attr('x2', widthLine);
				});
			});

			$('.tab[data-tab="'+urlReplace+'"]').trigger('click')

			$('body').attr('data-tab', urlReplace);
			dashboard.open(urlReplace);

			$('.tab').click(function(event) {

				var dataReport = $(this).attr('data-report'),
					dataTab = $(this).attr('data-tab');

				if(chart)
					chart.destroy();
				setTimeout(function() {
					if(options)
						chart = new Highcharts.Chart(options);
				}, 800);
				// chart = chart1; // <-------- Add this line here.

				// $('#graphics-datas').redraw();
				// chart.redraw();
				// $('#graphics-datas').highcharts().destroy();
				// $('#graphics-datas').highcharts().reflow();
				$('.tab').removeClass('active');
				$(this).addClass('active');

				$('.header').removeClass('open-tabs');
				$('.header .overlay').remove()
				$('body').removeClass('no-scroll');

				if (dataTab === 'profiles') {
					setTimeout(function() {
						$('body').attr('data-tab', dataTab);
						$('body').removeClass('loading');
					}, 800);
				}else{
					setTimeout(function() {
						$('body').attr('data-tab', dataTab);
					}, 800);
				}

				dashboard.open(dataTab, dataReport);
			});

			$('.bt-switch').on('click',function(event){
				event.preventDefault();

				var dataSwitch = $(this).attr('data-switch');
				$('[name="socialnetwork"]').val(dataSwitch);
				$('[name="onlyswitch"]').val(1);

				// console.log(dataSwitch);

				$('#form-pages').submit();
			})

			$('html').on('click', '.bt-print', function(event) {
                event.preventDefault();
                window.print();
            });
		},
		isDevice: function(){
			var device = {
				mobile: function(){
					var widthTabs = $('.tabs').width();

					var transform = 'translate(-'+widthTabs+'px)';

					$('.tabs').css({
						transform: transform
					});

					$('body').on('click', '.relatorio .search .controls-table', function(event) {
						// $(this).toggleClass('show-controll');

						if (!$(this).hasClass('show-controll')) {
							$(this).addClass('show-controll');
							$('body').append('<div class="overlay-controlls"></div>');
						}else{
							$(this).removeClass('show-controll');
							$('body .overlay-controlls').remove();
						}
					});

					$('body').on('click', '.overlay-controlls', function(event) {
						event.preventDefault();
						$('.controls-table').removeClass('show-controll');
						$('body .overlay-controlls').remove();
					});

					$('.bt-nav').on('click', function(event) {
						event.preventDefault();
						$('body').addClass('swipe-close');

						$('.header').addClass('open-tabs');
						// $('.header').append('<div class="overlay"></div>')
					});

					$('.tabs .bt-nav').click(function(event) {
						$('.header').removeClass('open-tabs');
						// $('.header .overlay').remove()
						$('body').removeClass('swipe-close');
					});

					$('.tabs').swipe({
						swipe: function(event, direction){
							if (direction === "left") {
								console.log('left')
								$('.header').removeClass('open-tabs');
								// $('.header .overlay').remove()
							}
						}
					})

					$('.header').on('click','.overlay', function(event) {
						$('.header').removeClass('open-tabs');
						// $('.header .overlay').remove()
						$('body').removeClass('swipe-close');
					});
				},
				desktop: function(){
				}
			}

			if (isMobile.any920()){
				device.mobile()
			}else{
				device.desktop()
			}
		},
		init: function(){
			// $('body').removeClass('loading');
			dashboard.events();
			dashboard.isDevice();
			dashboard.tab();

			$(window).resize(function(event) {
				dashboard.isDevice();
			});

			// if (urlReplace === "dashboard")

			// dashboard.setRankingDashboard(dashboardEngagement);
			$('select.styled').customSelect();

			//Se estamos no instagram 
			if($('.bt-switch').attr('data-switch') == 'facebook')
			{
				//esconde gráficos não usados
				$('.posts-per-month').hide();
			}
		}
	}

	return dashboard;
}();
