var engagement = function(){
	'use strict';

	var now, since, until, report;

	var engagement = {

		formatDate: function(){
			$('.until').val( date.getDate()+'/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear() );
			date.setHours(0,0,0,0);
			$('.since').val('01/'+('0'+(date.getMonth()+1)).slice(-2)+'/'+date.getFullYear());
		},

		initReport: function(){

			if( today.getDate()==date.getDate() &&
                today.getMonth()==date.getMonth() &&
                today.getYear()==date.getYear() )
            {
                until = Math.floor( today.getTime()/1000);
            }
            else until = Math.floor(date.getTime()/1000);

            var currentDate = new Date( until*1000 );
            currentDate = Math.floor(currentDate.setHours(0,0,0,0)/1000 )
            //ajuste para buscar posts que foram feitos no as 00:00 do dia
            until--;


            since = Math.floor(date.setDate('1')/1000);
            facebook.config.until =  until ;
            facebook.config.since = since;
            facebook.config.now = until;
            facebook.config.currentDate = currentDate;
    		facebook.config.callback = engagement.postsConsumptions;
			facebook.methods.initReport();

		    $('.table-responsive thead th').removeAttr('style');
		},

        postsConsumptions: function(){

            report  = facebook.report;

            engagement.totalsPerPage();

            for( var pageId in report.pages )
            {
                report.pages[pageId].avgEngagement = engagement.calculateEngagement(report.pages[pageId]);
            }

            engagement.totalConsumptions();
        },

        calculateEngagement: function(data){

                //calculo padrao brmalls
                data.avgLikes = data.totalLikes/data.totalPosts;
                data.avgComments = data.totalComments/data.totalPosts;
                data.avgShares = data.totalShares/data.totalPosts;
                data.avgViews = data.totalViews/data.totalPosts;

                if( isNaN(data.avgLikes) ) data.avgLikes = 0;
                if( isNaN(data.avgComments) ) data.avgComments = 0;
                if( isNaN(data.avgShares) ) data.avgShares = 0;
                if( isNaN(data.avgViews) ) data.avgViews = 0;

            if(parseInt(client_id) == 2)
            {
                var engagementTotal = data.avgShares + // media shares
                data.avgLikes + // media likes
                (data.avgComments /2) + // media comments/ 2
                (data.avgViews /2) // media views/2

                var avgEngagement = (engagementTotal*10000)/data.fans;

                return avgEngagement;
            }
            else
            {
                var engagementTotal = data.totalLikes + data.totalShares + data.totalComments;
                var avgEngagement = (engagementTotal/data.fans) * 100;
                return avgEngagement;
            }
        },

        totalsPerPage: function(){
            // console.log(report.pages);
            $.each(report.pages,function(i,page){
                report.pages[i].totalLikes = 0;
                report.pages[i].totalComments = 0;
                report.pages[i].totalShares = 0;
                report.pages[i].totalViews = 0;
                report.pages[i].totalReach = 0;
                report.pages[i].totalReachPaid = 0;
                report.pages[i].totalReachUnpaid = 0;
                $.each(page.posts, function(j,post){
                    report.pages[i].totalLikes += parseInt(post.likes);
                    report.pages[i].totalComments += parseInt(post.comments);
                    report.pages[i].totalShares += parseInt(post.shares);
                    report.pages[i].totalViews += parseInt(post.view);

                    report.pages[i].totalReach += parseInt(post.reach);
                    report.pages[i].totalReachPaid += parseInt(post.reachPaid);
					report.pages[i].totalReachUnpaid += parseInt(post.reachUnpaid);

                    // report.pages[i].totalImpressions += parseInt(post.impressions);
                })
            })
        },

		totalConsumptions: function(){
            // When a page has noyhing to process
            report.total = {
                fans : 0,
                reach : 0,
                reachPaid : 0,
				reachUnpaid : 0,
				impressions : 0,
				involvement : 0,
                interactions : 0,
                likes : 0,
                posts : 0,
                shares : 0,
                comments : 0,
                views : 0,
                page_engagement : 0,
                page_avg_engagement : 0
            }
            var totalProfiles = Object.keys(report.pages).length;

            var pageId;
            var admin = parseInt($('.tabs .reports').attr('data-admin'));

            for (pageId in report.pages)
            {
                //filter for admin pages
                if(admin === 1 || admin === 0)
                    if(admin != report.pages[ pageId ].admin)
                        continue;

                if( /[0-9]+/.test(pageId) )
                {
                    report.total.fans += report.pages[ pageId ].fans;
                    report.total.posts += report.pages[ pageId ].totalPosts;
                    
                    report.total.reach += report.pages[ pageId ].reach;
                    report.total.reachPaid += report.pages[ pageId ].reachPaid;
					report.total.reachUnpaid += report.pages[ pageId ].reachUnpaid;

					report.total.impressions += report.pages[ pageId ].impressions;
					report.total.involvement += report.pages[ pageId ].involvement;
                    report.total.interactions += report.pages[ pageId ].interactions;

                    report.total.likes += report.pages[ pageId ].totalLikes;
                    report.total.shares += report.pages[ pageId ].totalShares;
                    report.total.comments += report.pages[ pageId ].totalComments;
                    report.total.views += report.pages[ pageId ].totalViews;

                    if( !isNaN(report.pages[ pageId ].engagement) )
                    {
                        report.total.page_engagement += report.pages[ pageId ].engagement;
                    }

                    if( !isNaN(report.pages[ pageId ].avgEngagement) )
                    {
                        report.total.page_avg_engagement += report.pages[ pageId ].avgEngagement;
                    }
                }
            }

            report.total.avg_likes = engagement.calculateTotalAVG('likes');
            report.total.avg_shares = engagement.calculateTotalAVG('shares');
            report.total.avg_comments = engagement.calculateTotalAVG('comments');
            report.total.avg_views = engagement.calculateTotalAVG('views');

            report.total.engagement = engagement.getTotalEngagement();
            report.total.avg_engagement = engagement.calculateTotalAVG('engagement');
            report.total.page_engagement = report.total.page_engagement/totalProfiles;
            report.total.page_avg_engagement = report.total.page_avg_engagement/totalProfiles;

            engagement.sortPosts();
            // engagement.renderTotal();
            dashboard.close();
            setTimeout(function(){
                $('.search .admin').customSelect();
            },500)
        },

        calculateTotalAVG: function(property) {
            var result = 0;
            if( property!='engagement' )
            {
                result = ( report.total[property]/report.total.posts ).toFixed(2);
            }
            else{
                result = (report.total.engagement*10000)/report.total.fans;
            }

            if( !result || isNaN(result) ) result = 0;
            return result;
        },

        getTotalEngagement: function() {
            var result = report.total.shares/report.total.posts+ // media shares
                report.total.likes/report.total.posts + // media likes
                (report.total.comments/report.total.posts) /2 + // media comments/ 2
                (report.total.views/report.total.posts) /2 ; // media views/2

            if( !result || isNaN(result) ) result = 0;
            return result;
        },

        sortPosts: function (field,order){
            // set
            var orderedPages = [], i=0;
            var admin = parseInt($('.tabs .reports').attr('data-admin'));

            _.each(report.pages, function(element, index, list){

                //filter for admin pages
                if(admin === 1 || admin === 0)
                    if(admin != element.admin)
                        return true

                orderedPages[ i ] = {};
                orderedPages[ i ].id = element.id;
                orderedPages[ i ].name = element.name;
                orderedPages[ i ].fans = element.fans;
                orderedPages[ i ].totalPosts = parseInt( element.totalPosts );
                orderedPages[ i ].totalLikes = parseInt( element.totalLikes );
                orderedPages[ i ].totalShares = parseInt( element.totalShares );
                orderedPages[ i ].totalComments = parseInt( element.totalComments );
                orderedPages[ i ].totalViews = parseInt( element.totalViews );
                orderedPages[ i ].engagement = parseFloat( element.engagement.toFixed(2) );

                orderedPages[ i ].totalReach = parseInt( element.totalReach );
                orderedPages[ i ].totalReachPaid = parseInt( element.totalReachPaid );
                orderedPages[ i ].totalReachUnpaid = parseInt( element.totalReachUnpaid );
                
                orderedPages[ i ].reach = parseInt( element.reach );
				orderedPages[ i ].reachPaid = parseInt( element.reachPaid );
                orderedPages[ i ].reachUnpaid = parseInt( element.reachUnpaid );

				orderedPages[ i ].totalImpressions = parseInt( element.impressions );
				orderedPages[ i ].totalInvolvement = parseInt( element.involvement );
                orderedPages[ i ].totalInteractions = parseInt( element.interactions );

                if(isNaN(element.avgEngagement))
                    orderedPages[ i ].avgEngagement = 0;
                else
                    orderedPages[ i ].avgEngagement = Math.round(element.avgEngagement);

                orderedPages[ i ].avgLikes = parseFloat( element.avgLikes.toFixed(2) );
                orderedPages[ i ].avgComments = parseFloat( element.avgComments.toFixed(2) );
                orderedPages[ i ].avgShares = parseFloat( element.avgShares.toFixed(2) );
                orderedPages[ i ].avgViews = parseFloat( element.avgViews.toFixed(2) );

                orderedPages[ i ].class = (element.admin)?'admin':'';
                i++;
            })

            if(orderedPages.length>0)
            {
                $('.bt-csv, .bt-print').removeClass('disable');
            }
            else
            {
                $('.bt-csv, .bt-print').addClass('disable');
            }

            if(field)
            {
                if(order == 'desc')
                    orderedPages = _.sortBy(orderedPages,field).reverse();
                else
                    orderedPages = _.sortBy(orderedPages,field);
            }
            else
                orderedPages = _.sortBy(orderedPages,'engagement').reverse();

            var template = $( '.engagement-template' ).html()
            var templateCompiled = _.template( template)

            $( '.table-relatorio tbody' ).html('')

            _.each(orderedPages,function(el,i,list){
                $( '.table-relatorio tbody' ).append( templateCompiled({ page: el }) )
            })

            var $table = $('table.table-striped');
            $table.floatThead({
                scrollContainer: function($table){
                    return $table.closest('.wrapper');
                }
            });

            $('#report').val(JSON.stringify(report));
            $('.table-responsive').removeClass('loading');
        },

        renderTotal: function(){
            var totalProfiles = Object.keys(report.pages).length;

            var template = $( '.engagement-total-avg-template' ).html()
            var templateCompiled = _.template( template)

            // $( '.table-relatorio tfoot' ).html('')

            $( '.table-relatorio tfoot' ).append( templateCompiled({ data: report.total, totalProfiles: totalProfiles  }) )
            tables.tableRelatorio(true);
        },

        search: function(e){

    	    e.preventDefault();
            dashboard.open('engagement');
    	    //Se for post post-performance limpa o tbody
    	    $('.bt-csv, .bt-print').addClass('disable-button');
    	    $('.bt-postDeleted').addClass('disable');
    	    $('.table-responsive').removeClass('delete-table').addClass('loading').html(tableHtml);
    	    $('.posts-deleted').html('Posts deleted ...')
    	    $('.table-post tbody').html('');

    	    var sinceInput = $('.since').val();
    	    var untilInput = $('.until').val();
            var adminFilter = $('.search .admin').val();

    	    var sinceValue = sinceInput.split('/');
    	    var untilValue = untilInput.split('/');

    	    date = new Date( sinceValue[2],parseInt(sinceValue[1],'10')-1,sinceValue[0],0,0,0,0 );
    	    since = Math.floor(date.getTime()/1000);

    	    date = new Date( untilValue[2],parseInt(untilValue[1],'10')-1,untilValue[0],23,59,59,0 );

    	    if( today.getDate()==date.getDate() &&
    	        today.getMonth()==date.getMonth() &&
    	        today.getYear()==date.getYear() )
    	    {
    	        until = Math.floor( today.getTime()/1000);
    	    }
    	    else until = Math.floor(date.getTime()/1000);

    	    var currentDate = new Date( until*1000 );
    	    currentDate = Math.floor(currentDate.setHours(0,0,0,0)/1000 )
    	    //ajuste para buscar posts que foram feitos no as 00:00 do dia
    	    // until--;

            //set since and until for filters
            $('.tabs .reports') .attr('data-since',since)
                                .attr('data-until',until)
                                .attr('data-admin',adminFilter)

            engagement.renderTable();

    	    facebook.methods.createReport();
    		facebook.config.now = until;
    		facebook.config.currentDate = currentDate;
            //ajuste para buscar posts que foram feitos no as 00:00 do dia
            since--;
    		facebook.config.since = since;
    	    facebook.config.until =  until;
    		facebook.config.callback = engagement.postsConsumptions;
			facebook.methods.initReport();
        },

        open: function(e){
            // console.log('a')
            FB.getLoginStatus( function(response)
            {
                if (response.status === 'connected')
                {
                    setTimeout(function(){
                        engagement.renderTable();
                        engagement.postsConsumptions();
                        dashboard.close();
                    },600)
                }
                else
                {
                    window.location = rootURL;
                }
            });
        },

        renderTable: function(){
            var since = $('.tabs .reports').attr('data-since')
            var until = $('.tabs .reports').attr('data-until')
            var admin = $('.tabs .reports').attr('data-admin')

            var dateSince = new Date(since*1000)
            var dateUntil = new Date(until*1000)

            var template = $('.template-section-engagement').html();
            $('.relatorio').replaceWith(template);

            $('.search .since').val(pad(dateSince.getDate(),2) + '/' + pad((dateSince.getMonth()+1),2) + '/' + dateSince.getFullYear())
            $('.search .until').val(pad(dateUntil.getDate(),2) + '/' + pad((dateUntil.getMonth()+1),2) + '/' + dateUntil.getFullYear())
            $('.search .admin').val(admin);

            $('.search .until').datepicker({
				format: 'dd/mm/yyyy',
				todayBtn: true,
				todayHighlight: true,
				autoclose: true
			});

            $( ".search .since" ).datepicker({
				format: 'dd/mm/yyyy',
				todayBtn: true,
				todayHighlight: true,
				autoclose: true
			});
        },

		events: function(){

            $('html').on('click','[data-tab="engagement"] .bt-csv',function(event) {
			    event.preventDefault();

			    if( !$(this).hasClass('blocked') )
			    {
			        $('#formReport').submit();
			    }
			});

            $('html').on('click','[data-tab="engagement"] .bt-search',engagement.search);
            $('.list-tabs.reports [data-tab="engagement"]').on('click',engagement.open)
		},

		init: function(){
			engagement.events();
		}
	}

	return engagement;
}();
