var fanpages = function(){
	'use strict';

	var checkAll = false,
		countSelect,
		totalSelect

	var $scope = {
		countSelected : $('.count .selected'),
		countTotal : $('.count .total'),
		btSelect : $('.bt-select')
	};

	var fanpages = {
		checkSelectionAll: function(){
			countSelect = $('.page.selected').length;
			totalSelect = $('.page:not(.none)').length;

			$scope.countSelected.text(countSelect);
			$scope.countTotal.text(totalSelect);

			if (countSelect >= totalSelect) {
				checkAll = true;
				$scope.btSelect.text('Deselect all')
			}else{
				checkAll = false;
				$scope.btSelect.text('Select all')
			}
		},
		selectionAll: function(){
			if (checkAll === false) {
				$('.page:not(.none)').addClass('selected');
				checkAll = true;
				$('.page:not(.none)').find('input').prop('checked', true);
			}else{
				$('.page:not(.none)').removeClass('selected');
				checkAll = false;
				$('.page:not(.none)').find('input').prop('checked', false);
			}

			fanpages.checkSelectionAll();
		},
		selectPage: function(page){
			//desmarca todos
			$('.page:not(.none)').removeClass('selected');
				checkAll = false;
				$('.page:not(.none)').find('input').prop('checked', false);
				
			if (page.hasClass('selected')){
				page.removeClass('selected');
				page.find('input').prop('checked', false);
			}else{
				page.addClass('selected');
				page.find('input').prop('checked', true);
			}

			// fanpages.checkSelectionAll();
		},
		formSubmit: function(event){

            var pagesSelected = $('.page.selected').length
            if( pagesSelected == 0 )
            {
                event.preventDefault();
                $('.fanpages').addClass('alert');

                setTimeout(function() {
					$('.fanpages').removeClass('alert');                	
                }, 1000);
                // retornar erro para nenhuma pagina selecionada
            }
		},
		events: function(){

			$('.alert-box .bt-close').click(function(event) {
				event.preventDefault();
				$('.fanpages').removeClass('alert');
			});
			
			$('.pages').on('click','.page',function(event) {
				fanpages.selectPage($(this));
			});

			$scope.btSelect.on('click',function(event) {
				event.preventDefault();
				fanpages.selectionAll();
			});

			$('.form-pages').on('submit',fanpages.formSubmit);
			
			$('.bt-post').on('click',function(){
				$('[name="tipo"]').val('post-performance');
			})
			$('.bt-page').on('click',function(){
				$('[name="tipo"]').val('engagement');
			})
		},
		init: function(){
			// fanpages.checkSelectionAll();
			fanpages.events();
		}
	}

	return fanpages;
}();

fanpages.init();