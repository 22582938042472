var login = function(){
	'use strict';

	var autosubmit = false;

	var login = {
		
		init: function(){
			$('.bt-entrar').on('click',facebook.methods.login);
			$('.redes').on('click','.rede',login.submit);
		},

		checkPermission: function(result)
		{
			if(result.error)
			{
				if(result.no_pages)
				{
					$('.bt-entrar').removeClass('blocked');
					$('.login').removeClass('loading-facebook');
					$('.login').addClass('logar-facebook');
					setTimeout(function() {
						$('.login').addClass('not-admin');
					}, 1000);

					setTimeout(function() {
						$('.login').removeClass('not-admin');
					}, 30000);
				}
			}
			else
			{
				this.showNetworks();
			}
		},
		
		getPages: function(pages){
			// Check if the returned data exists and is larger than 1
			// console.log(pages);
			if( pages.length>0  )
			{
				var fanpage = '';

				for( var i in pages )
				{
					if(pages[i].facebook_id)
					{
						fanpage += '<li id="'+pages[i].facebook_id+
							'" class="page'+
							( ( pages[i].marked=='1' )?' selected':'')+
							( ( pages[i].allowed=='0' )?' none':'')+
							'">'+pages[i].name;

						fanpage += '<input type="checkbox" hidden name="pages['+
							pages[i].facebook_id+']" id="fb_'+pages[i].facebook_id+
							'" value="'+pages[i].name+'" ' +
							( ( pages[i].marked=='1' )?' checked="checked" ':'') +
							' /></li>';
					}
				}

				// Set the items for selection
				$('.pages ul').html( fanpage );
				// $('.bt-relatorio').show();
				var totalPages = $('.page:not(.none)').length;
				var pagesSelected = $('.page.selected').length;
				var totalNonePages = $('.page.none').length;
				login.selectFanpages(totalPages, pagesSelected);

				this.checkPages(pages);
				this.showNetworks();
			}
			// Caso o o usuário não possua nenhuma pagina ou não libere nenhum acesso
			else
			{
				login.selectFanpages(0,0);

				$('.login').removeClass('loading-facebook');
				$('.login').addClass('not-admin');

				setTimeout(function() {
					$('.login').removeClass('not-admin');
				}, 30000);

			}
		},

		checkPages: function(pages){
			if(pages.length > 1)
			{
				for( var i in pages )
				{
					if(pages[i].allowed == '1' &&  pages[i].marked == '1')
					{
						autosubmit = true;
						break;
					}
				}
			}else if (pages.length == 1){
				if(pages[0].allowed == '1')
				{
					autosubmit = true;
					$('#fb_'+pages[0].facebook_id).attr('checked',true);
				}
			}
		},

		selectFanpages: function(totalPages, pagesSelected){
			$('.count .selected').html(pagesSelected);
			$('.count .total').html(totalPages);

			if (pagesSelected < totalPages)
				$('.bt-select').html('Select all');
			else if(pagesSelected === totalPages)
				$('.bt-select').html('Deselect all');
		},

		showNetworks: function(){
			$('body').addClass('show-networks').removeClass('view-login');
			$('.login').removeClass('loading-facebook');
		},

		submit: function(){
			var socialnetwork = $(this).attr('data-network');
			
			$('[name="socialnetwork"]').val(socialnetwork)
			$('#form-network').submit();	
		}
	}

	return login;
}();

login.init();