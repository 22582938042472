var ranking = function(){
	'use strict';

	var report = { pages : {}, formatedPages:[], postsPerPage : [], posts : [], postsDeleted : [], postsRelated : [], relatedDone: [], pagesNotAdmin: [] };
	var access_token,until,since;
	var countRequests = 0;
    var month = null;

    var arrayMonths = {
        January: 01,
        February: 02,
        March: 03,
        April: 04,
        May: 05,
        June: 06,
        July: 07,
        August: 08,
        September: 09,
        October: 10,
        November: 11,
        December: 12
    }

	var ranking = {

		init: function(){
            ranking.events();
		},

        events: function(){
            $('.list-tabs.reports [data-tab="ranking"]').on('click',ranking.open)
            $('html').on('click','[data-tab="ranking"] .bt-search',ranking.search);
        },

        update: function(){
            if(socialnetwork == 'facebook')
            {
                // $.get(rootURL+'ranking/save',function(){
                // })
                $.get(rootURL+'dashboard/rankingpages',function(data)
                {
                    $('.top-ranking').removeClass('loading-widget');
                    $('.top-ranking table tbody').html(data)

                    // if(pagesForRanking)
                    // {
                    //     ranking.getAccessTokenMaster();
                    // }
                });
            }
            else
            {
                $.get(rootURL+'dashboard/rankingpages',function(data)
                {
                    $('.top-ranking').removeClass('loading-widget');
                    $('.top-ranking table tbody').html(data)
                });
            }
        },

        search: function(){
            month = $('input.months').attr('data-date')
			var fullDate = $('input.months').attr('data-full')

    	    var date = new Date( fullDate );

			//pega primeiro dia do mes
			var dateSince = new Date( date.getFullYear(),date.getMonth(),1,00,00,00,0 );
    	    since = Math.floor(dateSince.getTime()/1000);

			//pega primeiro dia do proximo mes e volta 1s
    	    var dateUntil = new Date( date.getFullYear(),date.getMonth()+1,1,00,00,00,0 );
			dateUntil = Math.floor(dateUntil.getTime()/1000)-1;

    	    if( today.getDate()==date.getDate() &&
    	        today.getMonth()==date.getMonth() &&
    	        today.getYear()==date.getYear() )
    	    {
    	        until = Math.floor( today.getTime()/1000);
    	    }
    	    else until = Math.floor(dateUntil);

			dashboard.open('ranking');
			FB.getLoginStatus( function(response)
            {
                if (response.status === 'connected')
                {
					ranking.renderTable();
					ranking.createReport();
		            ranking.initReport();
                }
                else
                {
                    window.location = rootURL;
                }
            });
        },

        open: function(){

            FB.getLoginStatus( function(response)
            {
                if (response.status === 'connected')
                {
					until = Math.floor( today.getTime()/1000);

					date.setHours(0,0,0,0)/1000;
					since = Math.floor(date.setDate('1')/1000);

					ranking.renderTable();
					ranking.createReport();
		            ranking.initReport();
                }
                else
                {
                    window.location = rootURL;
                }
            });
        },

		renderTable: function(){

            var template = $('.template-section-ranking').html();
            $('.relatorio').replaceWith(template);

        },

        getAccessTokenMaster: function(){
        	$.get(rootURL+'ranking/token',function(data){
        		access_token = data.token;
                if(data.refresh){
				    // ranking.atualizar();
                }
        	},'json')
        },

        atualizar: function(){
            ranking.createReport();
            ranking.initReport();
        },

		createReport: function(){

			report.postsPerPage = [];
            report.posts = [];
            report.postsDeleted = [];
            report.postsRelated = [];
            report.relatedDone = [];
            report.pagesNotAdmin = [];

            for( var pageId in pagesForRanking ){
               if( /[0-9]+/.test(pageId) )
                {
                    // Report data
                    report.pages[ pageId ] = { name: pagesForRanking[ pageId ] };

                    // Initialize count variables
                    report.pages[ pageId ].id = pageId;
                    report.pages[ pageId ].fans = 0;
                    report.pages[ pageId ].posts = {};
                    report.pages[ pageId ].totalPosts = 0;
                    report.pages[ pageId ].totalLikes = 0;
                    report.pages[ pageId ].totalShares = 0;
                    report.pages[ pageId ].totalComments = 0;
                    report.pages[ pageId ].totalViews = 0;
                    report.pages[ pageId ].totalEngagement = 0;
                    report.pages[ pageId ].engagement = 0;
                    report.pages[ pageId ].avgEngagement = 0;

                    report.pages[ pageId ].totalReachPaid = 0;
                    report.pages[ pageId ].totalReachUnpaid = 0;
                    report.pages[ pageId ].totalReach = 0;

					report.pages[ pageId ].impressions = 0;
					report.pages[ pageId ].totalImpressions = 0;
					report.pages[ pageId ].involvement = 0;
                    report.pages[ pageId ].totalInvolvement = 0;
					report.pages[ pageId ].interactions = 0;
                    report.pages[ pageId ].totalInteractions = 0;

                    report.pages[ pageId ].remainingPosts = 0;
                }
           }
        },

        request: function(path , callback){
           FB.api( path, { access_token:access_token }, function(response)
           {
                countRequests--;
                callback( response );
           });
        },

        initReport: function(){

			var currentDate = new Date( until*1000 );
			currentDate = Math.floor(currentDate.setHours(0,0,0,0)/1000 )
			//ajuste para buscar posts que foram feitos no as 00:00 do dia
			until--;

            // sempre inicia buscando page fans
            var path = 'insights?metric=page_fans,page_impressions,page_engaged_users,page_consumptions&ids='+
						Object.keys(pagesForRanking).join(',')+'&since='+(currentDate+86400)+'&until='+(until+86400)+
						'&access_token='+fbToken;

            countRequests++;
            ranking.request( path , ranking.getLikesPage );
        },

        getLikesPage: function(facebookData){
            var that = this;
            var likePerPage = {};
            var endTime = '';
            // for each page that returned
            for( var pageId in facebookData  )
            {
                if( 'data' in facebookData[pageId] && facebookData[pageId].data.length>0 )
                {
					var info = [];
					for (var i = facebookData[pageId].data.length - 1; i >= 0; i--) {
						if(facebookData[pageId].data[i].period == 'lifetime' || facebookData[pageId].data[i].period == 'days_28')
							info[facebookData[pageId].data[i].name] = facebookData[pageId].data[i].values[0];
					}

					var dayLikes = info['page_fans'].value;

                    // Get the data from that page and check if there is data
                    // totalPageFans[ pageId ] += dayLikes
					// console.log(info);
					report.pages[ pageId ].fans = info['page_fans'].value
					report.pages[ pageId ].impressions = info['page_impressions'].value
                    report.pages[ pageId ].involvement = info['page_engaged_users'].value
					report.pages[ pageId ].interactions = info['page_consumptions'].value

                    likePerPage[pageId] = dayLikes;
                    endTime = facebookData[pageId].data[0].values[0].end_time;
                }
            }

            facebook.methods.savePageFans(likePerPage, endTime);

            // Call the functions responsable for catch
            // data from the posts

            for( var pageId in pagesForRanking )
            {
                var path = '/'+ pageId+'/posts?fields=from,id,type,story,link,message,picture,'+
						'likes.limit(1).summary(true),comments.limit(1).summary(true),shares,created_time&include_hidden=true&limit=50&since='+
                        since+'&until='+until+
						'&access_token='+fbToken;

                //zera o total de posts
                report.pages[pageId].totalPosts = 0;

                countRequests++;
                ranking.request( path, ranking.getPostsPage );

            }

			setTimeout(function(){
				for( var pageId in pagesForRanking )
	            {
					var path = '/'+ pageId+'/promotable_posts?fields=from,id,type,story,link,message,picture,likes.limit(1).summary(true),comments.limit(1).summary(true),shares,created_time,permalink_url'+
					'&include_inline=true&include_hidden=true&limit=50&since='+
					since+'&until='+until+
					'&access_token='+fbToken;
					countRequests++;
					ranking.request( path, ranking.getPromotablePostsPage );
				}
			},'350');
        },

        getPostsPage: function(facebookData){
            // If there is posts on the page this month
            var postIds = [],
                pageId = null;

            if( ('data' in facebookData) && facebookData.data.length > 0 )
            {
                // Get shares, likes and comments from each post
                var posts = facebookData.data,
                    objectId = null,
                    pageId = facebookData.data[0].id.replace(/_[0-9]+/,'');

                report.postsPerPage[pageId] = posts.concat(report.postsPerPage[pageId]);

                for ( var i in posts )
                {
                    var currentPost = posts[i];

                    // Remove cover posts and status posts
                    if( currentPost.type!='status' && // if not post status
                        !( 'story' in currentPost &&
                            ( currentPost.story.indexOf('cover photo')>-1 || currentPost.story.indexOf('profile picture')>-1)
                        ) // if not cover photo
                        && currentPost.type != "event"
                        )
                    {

                        if( ('story' in currentPost) && currentPost.story.indexOf('new photos to the album')>-1 ){
                            objectId = currentPost.link.replace(/[a-zA-Z0-9.\/:_-]+a\.([0-9]+)\.[a-zA-Z0-9\/?=.]+/, '$1');
                            currentPost.id = pageId+'_'+objectId;
                            currentPost.object_id = objectId;
                            currentPost['link'] = 'https://www.facebook.com/'+pageId+'/posts/'+objectId;

                            var related = {};
                            related.id = pageId+'_'+objectId;
                            related.parent_id = currentPost.id;
                            report.postsRelated.push(related);

                        }

                        if( currentPost.type=='event' )
                        {
                            currentPost['link'] = 'https://www.facebook.com/'+pageId+'/posts/'+currentPost.object_id;
                        }

                        report.postsPerPage[pageId].push(currentPost);

                        // Get the consumption data
                        postIds.push( currentPost.id );
                    }
                }

                // Check if it's not empty
                report.pages[pageId].totalPosts += postIds.length;

                // Until only the ids that matter
                if( postIds.length>0 )
                {
                    report.pages[ pageId ].remainingPosts = 1
                    var path =  '/insights?metric=post_stories_by_action_type,'+
                                'post_consumptions_by_type,post_video_views,'+
								'post_impressions,'+
                                'post_impressions_by_paid_non_paid_unique&ids='+postIds.join(',')+
								'&access_token='+fbToken;

                    countRequests++;
                    ranking.request( path, ranking.getPostConsumption )
                }
            }

            if(facebookData.paging)
            {
                countRequests++;
                ranking.request( facebookData.paging.next + '&since='+since, ranking.getPostsPage );
            }

            ranking.checkRequests();
        },

		getPromotablePostsPage: function(facebookData){
			var postIds = [],
                pageId = null;

			if( ('data' in facebookData) && facebookData.data.length > 0 )
            {
                // Get shares, likes and comments from each post
                var posts = facebookData.data,
                    objectId = null,
                    pageId = facebookData.data[0].id.replace(/_[0-9]+/,''),
					reportPosts = report.postsPerPage[pageId],
					details,
                    validPost = true;

                for ( var i in posts )
                {
                    var currentPost = posts[i];

					details = _.where(reportPosts,{message:currentPost['message']});
                    details = details[0];
					if(!details)
					{
						report.postsPerPage[pageId].push(currentPost);
						// console.log(report.postsPerPage[pageId]);
						if( ('story' in currentPost) && currentPost.story.indexOf('new photos to the album')>-1 ){

                            objectId = currentPost.link.replace(/[a-zA-Z0-9.\/:_-]+a\.([0-9]+)\.[a-zA-Z0-9\/?=.]+/, '$1');
                            currentPost.id = pageId+'_'+objectId;
                            currentPost.object_id = objectId;
                            currentPost['link'] = 'https://www.facebook.com/'+pageId+'/posts/'+objectId;

                            var related = {};
                            related.id = pageId+'_'+objectId;
                            related.parent_id = currentPost.id;
                            report.postsRelated.push(related);

                            //add to posts
                            report.postsPerPage[pageId].push(currentPost);
                        }
                        else
						{
                            if(('link' in currentPost) && currentPost.link.indexOf('https://www.facebook.com/') !== -1)
                            {
                                // corrige erro para o post que adiciona várias fotos
                                // ele nao pode ser tratado como related, pois o related vai pegar apenas as metricas
                                // da primeira foto
                                var notnewphotos = true;
                                if( ('story' in currentPost) && currentPost.story.indexOf('new photos') !== -1)
                                    notnewphotos = false;

                                if(notnewphotos)
                                {
									// console.log('ei')
                                    // var link_id = currentPost.link.replace(/[a-zA-Z0-9.\/:_-]+a\.[0-9.]+\/([0-9]+)\/[a-zA-Z0-9\/?=.]+/, '$1');
                                    var link_id = currentPost.link.replace(/[a-zA-Z0-9.\/:_-]+\/([0-9]+)\//, '$1');
                                        link_id = link_id.replace('?type=3','');

                                    var full_link_id = pageId+'_'+link_id
									// console.log(full_link_id)
                                    if(full_link_id != currentPost.id)
                                    {
                                        var related = {};
                                        related.id = full_link_id;
                                        related.parent_id = currentPost.id;
                                        report.postsRelated.push(related);
                                    }
                                }
                            }
                        }

						//agora troca o link pelo permalink
                        currentPost['link'] = currentPost['permalink_url'];

                        // Get the consumption data
                        postIds.push( currentPost.id );
					}
				}
				// console.log(postIds)

				// Only the ids matter
                if( postIds.length>0 )
                {
                    report.pages[ pageId ].remainingPosts = 1
                    var path =  '/insights?metric=post_stories_by_action_type,'+
                                'post_consumptions_by_type,post_video_views,'+
								'post_impressions,'+
                                'post_impressions_by_paid_non_paid_unique&ids='+postIds.join(',')+
                                '&access_token='+fbToken;

                    countRequests++;
                    ranking.request( path, ranking.getPostConsumption )
                }

				if(facebookData.paging)
	            {
	                countRequests++;
	                ranking.request( facebookData.paging.next + '&since='+since, ranking.getPromotablePostsPage );
	            }

			}

			ranking.checkRequests();
		},

        getPostConsumption: function(facebookData){
            // post_stories_by_action_type: tab3 excell facebook
            // - Lifetime: The number of stories created about your Page post, by action type. (Total Count)
            // post_consumptions_by_type: tab4 excel facebook
            // - Lifetime: The number of people who clicked anywhere in your post, by type. Clicks generating stories are included in "Other Clicks." (Unique Users)

            var postIds = Object.keys(facebookData),
                pageId = postIds[0].replace(/_[0-9]+/,''),
                posts = report.postsPerPage[pageId],
                details;

            if( postIds.length>0 )
            {
                // get insights from related posts
                var relatedPostIds = []
                for(var index in report.postsRelated)
                {
                    relatedPostIds.push(report.postsRelated[index].id);
                }

                for( var post in facebookData )
                {
                    //detalhes do posts
                    details = _.where(posts,{id:post});
                    details = details[0];

                    var relation = _.findWhere(report.postsRelated, {parent_id: post});

                    // report.pages[pageId].posts[post] = {};
					report.pages[pageId].posts[post] = { likes:0, shares:0, comments:0, view:0 }
                    if( 'data' in facebookData[post] && facebookData[post].data.length>0 )
                    {
                        //loop to get all results
                        var info = [];
                        for (var i = facebookData[post].data.length - 1; i >= 0; i--) {
                            if(facebookData[post].data[i].period == 'lifetime')
                                info[facebookData[post].data[i].name] = facebookData[post].data[i].values[0];
                        }

						//se não tem impressions, o post é inválido
						if(!info['post_impressions'].value)
						{
							delete report.pages[pageId].posts[post];
						}
						else
						{

	                        // Get the likes, shares and comments
	                        var values = info['post_stories_by_action_type'].value

	                        if( 'like' in values ){
	                            report.pages[pageId].posts[post].likes = parseInt(values.like);
	                            if(!relation)
	                                report.pages[pageId].totalLikes += parseInt(values.like);
	                        }
	                        else
	                            report.pages[pageId].posts[post].likes = 0;

	                        if( 'share' in values ){
	                            report.pages[pageId].posts[post].shares = parseInt(values.share);
	                            if(!relation)
	                                report.pages[pageId].totalShares += parseInt(values.share);
	                        }
	                        else
	                            report.pages[pageId].posts[post].shares = 0;

	                        if( 'comment' in values ){
	                            report.pages[pageId].posts[post].comments = parseInt(values.comment);
	                            if(!relation)
	                                report.pages[pageId].totalComments += parseInt(values.comment)
	                        }
	                        else
	                            report.pages[pageId].posts[post].comments = 0;

	                        // video views
	                        if( 'post_video_views' in info && details.type == 'video')
	                        {
	                            report.pages[pageId].posts[post].view = info['post_video_views'].value
	                        }
	                        else{
	                            // Get the links_clicks, other_clicks and photo_view values - post_consumptions_by_type
	                            values = info['post_consumptions_by_type'].value
	                            if( 'photo view' in values )
	                            {
	                                report.pages[pageId].posts[post].view = parseInt(values['photo view']);
	                            }
	                            else if( 'link clicks' in values )
	                            {
	                                report.pages[pageId].posts[post].view = parseInt(values['link clicks']);
	                            }
	                            else{
	                                report.pages[pageId].posts[post].view = 0;
	                            }
	                        }
	                        if(!relation)
	                            report.pages[pageId].totalViews += report.pages[pageId].posts[post].view;

	                        //reach
	                        values = info['post_impressions_by_paid_non_paid_unique'].value

	                        report.pages[pageId].posts[post].reach = parseInt(values.total);
	                        if(!relation)
	                            report.pages[pageId].totalReach += parseInt(values.total);

	                        report.pages[pageId].posts[post].reachPaid = parseInt(values.paid);
	                        if(!relation)
	                            report.pages[pageId].totalReachPaid += parseInt(values.paid);

	                        report.pages[pageId].posts[post].reachUnpaid = parseInt(values.unpaid);
	                        if(!relation)
	                            report.pages[pageId].totalReachUnpaid += parseInt(values.unpaid);

	                        // Calculate the engagement of the users per post
	                        var engagement = report.pages[pageId].posts[post].shares + // media shares
	                                report.pages[pageId].posts[post].likes + // media likes
	                                (report.pages[pageId].posts[post].comments /2) + // media comments/ 2
	                                (report.pages[pageId].posts[post].view /2) // media views/2

	                        report.pages[pageId].posts[post].engagement = (Math.round(engagement * 100) / 100).toFixed(2);
	                        report.pages[pageId].totalEngagement += engagement;

	                        var dateSplited = details.created_time.split(/[^0-9]+/);
	                        var created_at = new Date (dateSplited[0],dateSplited[1]-1,dateSplited[2],dateSplited[3],dateSplited[4],dateSplited[5] );
	                        var created_at = pad(created_at.getDate(),2) + '/' + pad(created_at.getMonth()+1,2) + '/' + created_at.getFullYear() + ' ' + pad(created_at.getHours(),2) + ':' + pad(created_at.getMinutes(),2)

	                        report.pages[pageId].posts[post].link = details.link
	                        report.pages[pageId].posts[post].message = details.message
	                        report.pages[pageId].posts[post].picture = details.picture
	                        report.pages[pageId].posts[post].created_at = created_at

	                        report.pages[pageId].posts[post].page_id = pageId
	                        report.pages[pageId].posts[post].post_id = post
						}
                    }
                    else
                    {
                        if(details.comments)
                           report.pages[pageId].posts[post].comments = details.comments.summary.total_count
                        else
                           report.pages[pageId].posts[post].comments = 0;

                        if(details.likes)
                           report.pages[pageId].posts[post].likes = details.likes.summary.total_count
                        else
                           report.pages[pageId].posts[post].likes = 0;

                        if(details.shares)
                           report.pages[pageId].posts[post].shares = details.shares.count
                        else
                           report.pages[pageId].posts[post].shares = 0;

                        report.pages[pageId].posts[post].view = 0;

                        report.pages[pageId].posts[post].engagement = 0;

                        report.pages[pageId].posts[post].reach = 0;
                        report.pages[pageId].posts[post].reachPaid = 0;
                        report.pages[pageId].posts[post].reachUnpaid = 0;

                        report.pages[pageId].posts[post].link = details.link
                        report.pages[pageId].posts[post].message = details.message
                        report.pages[pageId].posts[post].picture = details.picture
                        report.pages[pageId].posts[post].created_at = created_at

                        report.pages[pageId].posts[post].page_id = pageId
                        report.pages[pageId].posts[post].post_id = post
                    }
                }

				// Check if it's not empty
                report.pages[pageId].totalPosts = Object.keys(report.pages[pageId].posts).length;

                if(relatedPostIds.length>0)
                {
                    var path =  '/insights?metric=post_stories_by_action_type,'+
                                'post_consumptions_by_type,post_video_views,'+
                                'post_impressions_by_paid_non_paid_unique&ids='+relatedPostIds.join(',');
                    countRequests++;
                    ranking.request( path, ranking.getRelatedPostConsumption )
                }

                ranking.checkRequests();
            }
        },

        getRelatedPostConsumption: function(facebookData){
            var postIds = Object.keys(facebookData),
                details;

            if( postIds.length>0 )
            {
                for( var post in facebookData )
                {
                    var pageId = post.replace(/_[0-9]+/,'');
                    var posts = report.postsPerPage[pageId];

                    if( 'data' in facebookData[post] && facebookData[post].data.length>0 )
                    {
                        var done = false;
                        var relation = _.findWhere(report.postsRelated, {id: post});

                        details = _.where(posts,{id:relation.parent_id});
                        details = details[0];

                        done = _.indexOf(report.relatedDone,relation.parent_id);
                        if(report.pages[pageId].posts[relation.parent_id])
                        {
                            //loop to get all results
                            var info = [];
                            for (var i = facebookData[post].data.length - 1; i >= 0; i--) {
                                info[facebookData[post].data[i].name] = facebookData[post].data[i].values[0];
                            }

                            // Get the likes, shares and comments
                            var values = info['post_stories_by_action_type'].value

                            if( 'like' in values ){
                                report.pages[pageId].posts[relation.parent_id].likes = parseInt(values.like);
                            }

                            if( 'share' in values )
                                report.pages[pageId].posts[relation.parent_id].shares = parseInt(values.share);

                            if( 'comment' in values )
                                report.pages[pageId].posts[relation.parent_id].comments = parseInt(values.comment);

                            if( 'post_video_views' in info && details.type == 'video')
                            {
                                report.pages[pageId].posts[relation.parent_id].view = info['post_video_views'].value
                            }
                            else{
                                // Get the links_clicks, other_clicks and photo_view values - post_consumptions_by_type
                                values = info['post_consumptions_by_type'].value
                                if( 'photo view' in values )
                                {
                                    report.pages[pageId].posts[relation.parent_id].view = parseInt(values['photo view']);
                                }
                                else if( 'link clicks' in values )
                                {
                                    report.pages[pageId].posts[relation.parent_id].view = parseInt(values['link clicks']);
                                }
                                else{
                                    report.pages[pageId].posts[relation.parent_id].view = 0;
                                }
                            }

                            //reach
                            values = info['post_impressions_by_paid_non_paid_unique'].value

                            report.pages[pageId].posts[relation.parent_id].reach = parseInt(values.total);
                            report.pages[pageId].posts[relation.parent_id].reachPaid = parseInt(values.paid);
                            report.pages[pageId].posts[relation.parent_id].reachUnpaid = parseInt(values.unpaid);


                            // Recalculate the engagement of the users per post
                            var engagement = report.pages[pageId].posts[relation.parent_id].shares + // media shares
                                    report.pages[pageId].posts[relation.parent_id].likes + // media likes
                                    (report.pages[pageId].posts[relation.parent_id].comments /2) + // media comments/ 2
                                    (report.pages[pageId].posts[relation.parent_id].view /2) // media views/2

                            report.pages[pageId].posts[relation.parent_id].engagement = (Math.round(engagement * 100) / 100).toFixed(2);

                            report.relatedDone.push(relation.parent_id);
                        }

                    }
                }
            }

            ranking.checkRequests();
        },

        sortReport: function(field,order){
			// console.log(report.pages)
            _.each(report.pages, function(element, index, list){
                var i = 0;
                if(element.totalPosts > 0)
                {
                    var posts = [];
                    var shopping = element.name,
                        totalEngagement = element.totalEngagement;

                    _.each(element.posts,function(element,index,list){
                        posts[i] = {};
                        posts[i].shopping = shopping

                        posts[i].likes = parseInt(element.likes)
                        posts[i].shares = parseInt(element.shares)
                        posts[i].comments = parseInt(element.comments)
                        posts[i].views = parseInt(element.view)
                        posts[i].engagement = parseFloat(element.engagement);
                        posts[i].percentage = parseFloat(element.engagement*100/totalEngagement);
                        if( isNaN(posts[i].percentage) ) posts[i].percentage=0;

                        posts[i].reach = parseInt(element.reach)
                        posts[i].reachPaid = parseInt(element.reachPaid)
                        posts[i].reachUnpaid = parseInt(element.reachUnpaid)

                        posts[i].link = element.link
                        posts[i].message = (_.isString(element.message))?element.message:''
                        posts[i].picture = element.picture
                        posts[i].created_at = element.created_at

                        posts[i].facebook_id = element.post_id
                        posts[i].page_facebook_id = element.page_id
                        i++;
                    });

                    // console.log(posts);
                    posts = _.sortBy(posts,'engagement').reverse();
                    // ranking.savePosts(posts);
                }
            })
			// console.log(posts)
        },

		totalConsumptions: function(){
            // When a page has noyhing to process
            report.total = {
                fans : 0,
                reach : 0,
                reachPaid : 0,
				reachUnpaid : 0,
				impressions : 0,
				involvement : 0,
                interactions : 0,
                likes : 0,
                posts : 0,
                shares : 0,
                comments : 0,
                views : 0,
                page_engagement : 0,
                page_avg_engagement : 0
            }
            var totalProfiles = Object.keys(report.pages).length;

            var pageId;

            for (pageId in report.pages)
            {
                if( /[0-9]+/.test(pageId) )
                {
                    report.total.fans += report.pages[ pageId ].fans;
                    report.total.posts += report.pages[ pageId ].totalPosts;

                    report.total.reach += report.pages[ pageId ].totalReach;
                    report.total.reachPaid += report.pages[ pageId ].totalReachPaid;
					report.total.reachUnpaid += report.pages[ pageId ].totalReachUnpaid;

					report.total.impressions += report.pages[ pageId ].impressions;
					report.total.involvement += report.pages[ pageId ].involvement;
                    report.total.interactions += report.pages[ pageId ].interactions;

                    report.total.likes += report.pages[ pageId ].totalLikes;
                    report.total.shares += report.pages[ pageId ].totalShares;
                    report.total.comments += report.pages[ pageId ].totalComments;
                    report.total.views += report.pages[ pageId ].totalViews;
                }
            }

            ranking.sortPages();
            // engagement.renderTotal();
			// console.log(report.total)
            dashboard.close();
        },

		sortPages: function (field,order){
            // set
            var orderedPages = [], i=0;

            _.each(report.pages, function(element, index, list){

                orderedPages[ i ] = {};
                orderedPages[ i ].id = element.id;
                orderedPages[ i ].name = element.name;
                orderedPages[ i ].fans = element.fans;
                orderedPages[ i ].totalPosts = parseInt( element.totalPosts );
                orderedPages[ i ].totalLikes = parseInt( element.totalLikes );
                orderedPages[ i ].totalShares = parseInt( element.totalShares );
                orderedPages[ i ].totalComments = parseInt( element.totalComments );
                orderedPages[ i ].totalViews = parseInt( element.totalViews );
                orderedPages[ i ].engagement = parseFloat( element.engagement.toFixed(2) );

                orderedPages[ i ].totalReach = parseInt( element.totalReach );
                orderedPages[ i ].totalReachPaid = parseInt( element.totalReachPaid );
				orderedPages[ i ].totalReachUnpaid = parseInt( element.totalReachUnpaid );

				orderedPages[ i ].totalImpressions = parseInt( element.impressions );
				orderedPages[ i ].totalInvolvement = parseInt( element.involvement );
                orderedPages[ i ].totalInteractions = parseInt( element.interactions );

                i++;
            })

            if(orderedPages.length>0)
            {
                $('.bt-csv, .bt-print').removeClass('disable');
            }
            else
            {
                $('.bt-csv, .bt-print').addClass('disable');
            }

            if(field)
            {
                if(order == 'desc')
                    orderedPages = _.sortBy(orderedPages,field).reverse();
                else
                    orderedPages = _.sortBy(orderedPages,field);
            }
            else
                orderedPages = _.sortBy(orderedPages,'totalImpressions').reverse();

			// console.log(orderedPages);
            var template = $( '.ranking-template' ).html()
            var templateCompiled = _.template( template)

            $( '.table-relatorio tbody' ).html('')

            _.each(orderedPages,function(el,i,list){
                $( '.table-relatorio tbody' ).append( templateCompiled({ page: el }) )
            })

            var $table = $('table.table-striped');
            $table.floatThead({
                scrollContainer: function($table){
                    return $table.closest('.wrapper');
                }
            });

            $('#report').val(JSON.stringify(report));
            $('.table-responsive').removeClass('loading');
			ranking.dateSearch();
        },

		dateSearch: function(){
			var orderCollumn = 3;

			var t = $('.table-relatorio').DataTable({
			    searching: false,
			    paging: false,
			    info: false,
			    "order": [[ orderCollumn, "desc" ]],
			    ordering:  true
			});

			t.on( 'order.dt search.dt', function () {
			    t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
			        cell.innerHTML = i+1;
			    } );
			} ).draw();

			$('.dataTables_length').appendTo('.search-date');
			$('.dataTables_info, .dataTables_paginate').appendTo('.table-pagination');

			$('.months').datepicker({
			    format: 'MM',
			    viewMode: 'year',
			    minViewMode: 'year',
			    language: 'en-US',
			    autoclose: true,
			    minViewMode: 1,
			}).on('changeDate', function(e,f,g) {
			    var currentVal = $(this).val();

				$('.months').attr('data-full', e.date);
			    $.each(arrayMonths, function(index, val) {
			        if (index === currentVal) {
						$('.months').attr('data-date', val);
			        };
			    });
			});

			var mm = new Date( since * 1000 );
				mm = mm.getMonth()+1;

			if(mm<10)
			    mm='0'+mm;

			// set date to calendar
			$( ".months" ).datepicker( "setDate", mm );

			$('.table-relatorio').floatThead();

			t.order( [ 4, 'desc' ] ).draw();
		},

        savePosts: function(posts){
        	// console.log(report);
        	// console.log(posts);
        	var stringPosts = JSON.stringify(posts)

        	$.post(
        	    rootURL+'posts/save', { posts: stringPosts }, function(response){
        	        // console.log(response);
        		}
        	)
        },

        checkRequests: function(){
            if( !countRequests )
            {
				// ranking.sortReport();
                ranking.totalConsumptions();
            }
        }
	}

	return ranking;
}();
